// extracted by mini-css-extract-plugin
export var captcha = "CaptchaInfo-module--captcha--65253";
export var white = "CaptchaInfo-module--white--03c69";
export var bodyshop = "CaptchaInfo-module--bodyshop--f8b8b";
export var text = "CaptchaInfo-module--text--939aa";
export var fintech = "CaptchaInfo-module--fintech--89aa8";
export var owlSoul = "CaptchaInfo-module--owlSoul--a7518";
export var bishkek = "CaptchaInfo-module--bishkek--20625";
export var london = "CaptchaInfo-module--london--01a1c";
export var tooltip = "CaptchaInfo-module--tooltip--9f037";
export var error = "CaptchaInfo-module--error--00d1c";