import React from 'react';
import cn from 'classnames';
import * as styles from './Container.module.scss';

function Container({ className, background = 'white', padding, theme, margin, children, id }) {
  const Wrapper = theme === 'inner' ? 'div' : 'section';

  return (
    <Wrapper
      className={cn(
        className,
        styles.container,
        styles[`${background}BG`],
        padding && styles[`padding${padding}`],
        margin && styles[`margin${margin}`],
        theme && styles[theme],
      )}
      id={id}
    >
      {children}
    </Wrapper>
  );
}

export default Container;
