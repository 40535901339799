import React, { Suspense, lazy } from 'react';
import { useIsSsr } from 'utils/hooks';

const SlickSlider = lazy(() => import('react-slick').then(module => ({ default: module.default })));

const BasicSlider = React.forwardRef(({ children, className, ...props }, ref) => {
  if (useIsSsr()) return null;

  return (
    <Suspense fallback={null}>
      <SlickSlider {...props} ref={ref}>
        {children}
      </SlickSlider>
    </Suspense>
  );
});

BasicSlider.displayName = 'Slider';

export default BasicSlider;
