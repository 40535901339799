import React from 'react';
import cn from 'classnames';
import { Subtitle, Icon, Modal, CloseButton } from '@landing/ui';
import * as styles from './SuccessModal.module.scss';

export default function SuccessModal({ isOpen, closeModal, text }) {
  return (
    <Modal
      isOpen={isOpen}
      closeButton={false}
      animation="opacity"
      closeModalCallback={closeModal}
      theme="send"
    >
      {onClose => (
        <>
          <Icon name="letter-success" className={cn(styles.icon, 'svg-icon')} />
          <CloseButton onClose={onClose} />
          <div className={styles.wrapper}>
            <Subtitle className={styles.text}>{text}</Subtitle>
          </div>
        </>
      )}
    </Modal>
  );
}
