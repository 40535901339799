import React from 'react';
import PostSendModal from '../PostSendModal/PostSendModal';
import { Subtitle, Icon } from '@landing/ui';
import { defaultModalsId } from '../constants';
import { email } from 'define';

import * as styles from './ErrorModal.module.scss';

export default function ErrorModal() {
  return (
    <PostSendModal
      Icon={() => <Icon name="warning" className={styles.icon} />}
      modalID={defaultModalsId.errorModal}
    >
      <div className={styles.wrapper}>
        <Subtitle className={styles.text}>Sorry, your request has not been sent.</Subtitle>
        <Subtitle className={styles.contact}>
          {'Please, try again or contact us by email '}
          <a href={`mailto:${email}`} className={styles.link}>
            {email}
          </a>
        </Subtitle>
      </div>
    </PostSendModal>
  );
}
