import React from 'react';
import * as styles from './Switcher.module.scss';

export default function Switcher({ name, leftItem, rightItem, checked, onChange }) {
  return (
    <label className={styles.container}>
      {leftItem && <span className={styles.label}>{leftItem}</span>}
      <input
        type="checkbox"
        name={name}
        className={styles.input}
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.switcher}></span>
      {rightItem && <span className={styles.label}>{rightItem}</span>}
    </label>
  );
}
