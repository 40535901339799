// extracted by mini-css-extract-plugin
export var wrap = "Loader-module--wrap--034d7";
export var fullHeight = "Loader-module--fullHeight--c18af";
export var big = "Loader-module--big--c46c4";
export var loader = "Loader-module--loader--87b9f";
export var item = "Loader-module--item--4d4dc";
export var page = "Loader-module--page--8173c";
export var block = "Loader-module--block--2d0bc";
export var button = "Loader-module--button--996f8";
export var white = "Loader-module--white--1efe4";
export var ldsRing = "Loader-module--lds-ring--c9cdd";