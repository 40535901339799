import { useCallback, useState } from 'react';

export const useStateModal = () => {
  const [modalState, setModalState] = useState({ isOpen: false });

  const showModal = useCallback(() => setModalState({ isOpen: true }), []);
  const hideModal = useCallback(() => setModalState({ isOpen: false }), []);

  return { modalState, showModal, hideModal };
};
