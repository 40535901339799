import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { modal as modalStore } from 'store';
import { Link } from '@landing/ui';
import { Button } from 'components/UI';
import BurgerBtn from './components/BurgerBtn';
import MobileMenu from './components/MobileMenu';
import AnchorMenu from './components/AnchorMenu';

import * as styles from './Header.module.scss';

export default function Header({ anchorLinks }) {
  const [headerFixed, setHeaderFixed] = useState(false);
  const [mobileMenuVisible, setActiveMobileMenu] = useState(false);

  const toggleMobileMenu = useCallback(() => {
    setActiveMobileMenu(!mobileMenuVisible);
  }, [mobileMenuVisible]);

  const handlerScroll = useCallback(() => {
    window.pageYOffset > 20 ? setHeaderFixed(true) : setHeaderFixed(false);
  }, []);

  const onLinkClick = useCallback(() => {
    setActiveMobileMenu(false);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll, { passive: true });
    handlerScroll();

    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, []);

  const callModal = () => {
    modalStore.open('request-consultation');
  };

  return (
    <header
      className={cn(styles.header, headerFixed && styles.fixed, mobileMenuVisible && styles.active)}
    >
      <div className={styles.layout}>
        <Link href="https://andersenlab.com" className={styles.logoWrap}>
          <div className={styles.logo} />
        </Link>
        <div className={styles.navigation}>
          <AnchorMenu theme={headerFixed && 'dark'} anchorLinks={anchorLinks} />
        </div>
        <div className={styles.touchPanel}>
          <Button
            className={styles.button}
            onClick={callModal}
            size="sm"
            theme={!headerFixed && 'outlined'}
          >
            Register
          </Button>
          <BurgerBtn
            fixed={mobileMenuVisible}
            active={mobileMenuVisible}
            onClick={toggleMobileMenu}
            theme={headerFixed && 'dark'}
          />
          {mobileMenuVisible && <MobileMenu anchorLinks={anchorLinks} onLinkClick={onLinkClick} />}
        </div>
      </div>
    </header>
  );
}
