import React from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import * as styles from './CloseButton.module.scss';

export default function CloseButton({ className, onClose, icon, size, theme }) {
  return (
    <button
      className={cn(icon ? styles.closeIcon : styles.close, className, styles[size], styles[theme])}
      onClick={onClose}
    >
      {icon && <Icon name="close" className="svg-icon-20" />}
    </button>
  );
}
