import React from 'react';
import cn from 'classnames';

import * as styles from './FormGrid.module.scss';

export const FormColumn = ({ sizes = [], children }) => {
  return (
    <div
      className={cn(
        styles.column,
        sizes.map(el => styles[el]),
      )}
    >
      {children}
    </div>
  );
};
