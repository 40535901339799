import { getCookie } from './cookie';

const COOKIE_NAME = 'x-country-code';

export function getCountryCode() {
  return getCookie(COOKIE_NAME);
}

export function isRequestedFrom(countryCode = '') {
  if (Array.isArray(countryCode)) {
    return countryCode.includes(getCountryCode()?.toLowerCase());
  }

  return getCountryCode()?.toLowerCase() === countryCode?.toLowerCase();
}
