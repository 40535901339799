import React, { useEffect } from 'react';
import { lockUnlockPage } from '../../utils';
import AnchorMenu from '../AnchorMenu';

import * as styles from './MobileMenu.module.scss';

export default function MobileMenu({ anchorLinks, onLinkClick }) {
  useEffect(() => {
    lockUnlockPage.lock();
    return () => lockUnlockPage.unlock();
  }, []);

  return (
    <div className={styles.mobileMenu}>
      <div className={styles.container}>
        <AnchorMenu anchorLinks={anchorLinks} theme="mobileMenu" onLinkClick={onLinkClick} />
      </div>
    </div>
  );
}
