import React, { forwardRef } from 'react';
import cn from 'classnames';

import * as styles from './Markup.module.scss';

function Markup({ tag = 'div', className, children, ...rest }, ref) {
  const Wrapper = tag;

  return (
    <Wrapper
      ref={ref}
      className={cn(styles.markup, className)}
      {...rest}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
}

export default forwardRef(Markup);
