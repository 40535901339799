import React from 'react';
import cn from 'classnames';
import * as styles from './List.module.scss';

export default function List({
  items = [],
  theme,
  size = 'md',
  marker = 'checked',
  className,
  itemTag,
}) {
  const ItemWrapper = itemTag;

  return (
    <ul className={cn(styles.list, marker && styles[marker], theme && styles[theme], className)}>
      {items.map(item => (
        <li key={item} className={cn(styles.item, styles[size])}>
          {itemTag ? <ItemWrapper className={styles.itemWrapper}>{item}</ItemWrapper> : item}
        </li>
      ))}
    </ul>
  );
}
