import React from 'react';
import { Icon, Link } from '@landing/ui';

import * as styles from './SocialButtons.module.scss';

export default function SocialButtons({ items = {} }) {
  return (
    <>
      {items.map(({ url, icon, title }) => (
        <Link key={title} href={url} className={styles.link}>
          <Icon className={styles.icon} name={icon} />
          {title}
        </Link>
      ))}
    </>
  );
}
