import React from 'react';
import cn from 'classnames';
import { Title, List } from '@landing/ui';
import * as styles from './DirectionCard.module.scss';

const DirectionCard = ({ area, title, list, handleClick }) => {
  const style = {
    '--template-area': area,
  };

  return (
    <article className={styles.card} style={style} onClick={handleClick}>
      <Title tag="h3" className={styles.title}>
        {title}
      </Title>
      {list && <List className={cn(styles.list, styles[area])} items={list} />}
      <button className={styles.button}>More</button>
    </article>
  );
};

export default DirectionCard;
