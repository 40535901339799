import React from 'react';
import cn from 'classnames';
import * as styles from './Main.module.scss';

export default function Main({
  className,
  paddingDisable,
  marginDisable,
  background,
  scrollY,
  overflow,
  theme,
  children,
}) {
  return (
    <main
      className={cn(
        className,
        styles.content,
        paddingDisable && styles[`${paddingDisable}PaddingNone`],
        marginDisable && styles.marginDisable,
        background && styles[`${background}BG`],
        scrollY && styles.scrollY,
        overflow && styles[overflow],
        theme && styles[theme],
      )}
    >
      {children}
    </main>
  );
}
