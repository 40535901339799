import React from 'react';
import cn from 'classnames';
import { Link, Title, Image } from '@landing/ui';

import * as styles from './Card.module.scss';

export default function Card({ title, link, logo, date, className }) {
  return (
    <Link href={link} className={cn(styles.card, className)}>
      <div className={styles.content}>
        <Image src={logo} className={styles.picture} classImage={styles.image} alt={title} />
        <Title className={styles.title}>{title}</Title>
      </div>
      <p className={styles.date}>{date}</p>
    </Link>
  );
}
