import React from 'react';
import { withFormik } from 'formik';

import { useIsSsr } from 'utils/hooks';
import { ErrorsShakeHubContext } from 'components/forms/utils';

import { createInitialValues, submitHandler, createFields } from './utils';

export default function withForm({ fields, validation, submit, nda, ...rest }) {
  return function (Component) {
    const submitObj = submit;

    if (typeof submitObj.focus === 'undefined') {
      submitObj.focus = true;
    }

    const reset = { reset: submitObj.reset ?? true, resetHandler: null };
    let onSuccess = submitObj.onSuccess;
    const enhancedFields = createFields(fields, nda);

    let additionalFormData = submitObj.additionalFormData;
    let values = {};

    return withFormik({
      mapPropsToValues: () => createInitialValues(enhancedFields),
      validationSchema: () => validation(values),
      handleSubmit: (values, { setStatus }) =>
        submitHandler({
          values,
          formName: submitObj.formName,
          handler: submitObj.handler,
          onSuccess,
          onError: submitObj.onError,
          successModal: values?.dateTime
            ? 'SuccessfulBookingModal'
            : submitObj.successModal ?? 'SuccessModal',
          errorModal: submitObj.errorModal ?? 'ErrorModal',
          onResponse: submitObj.onResponse,
          reset,
          setStatus,
          focus: submitObj.focus,
          additionalFormData,
          getAdditionalFormData: submitObj.getAdditionalFormData,
          withoutCaptchaToken: submitObj.withoutCaptchaToken,
          pushSubmitEvent: submitObj.pushSubmitEvent,
        }),
    })(props => {
      reset.resetHandler = props.resetForm;
      onSuccess = props.onSuccess ?? onSuccess;
      additionalFormData = props.additionalFormData ?? additionalFormData;
      values = props.values ?? values;

      return (
        !useIsSsr() && (
          <div>
            <ErrorsShakeHubContext.Provider value={new EventTarget()}>
              <Component
                {...props}
                {...rest}
                fields={enhancedFields}
                nda={nda}
                values={props.values}
                submit={submitObj}
              />
            </ErrorsShakeHubContext.Provider>
          </div>
        )
      );
    });
  };
}
