import React from 'react';
import cn from 'classnames';
import { Collapse } from 'react-collapse';
import './Collapse.scss';

export default function CollapseEl({ toggle, initialStyle = {}, children, className }) {
  return (
    <Collapse
      initialStyle={initialStyle}
      isOpened={toggle}
      theme={{ collapse: cn('ReactCollapse--collapse', className) }}
    >
      {children}
    </Collapse>
  );
}
