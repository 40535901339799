import React from 'react';
import { CaptchaInfo, Container } from '@landing/ui';

import * as styles from './Copyright.module.scss';

export default function Copyright() {
  return (
    <Container className={styles.wrapper} background="gray">
      <div className={styles.layout}>
        <span>© Andersen Company, 2022</span>
        <CaptchaInfo className={styles.captcha} />
      </div>
    </Container>
  );
}
