import React from 'react';
import { Modal, CloseButton } from '@landing/ui';
import { YoutubePlayer } from '@landing/components';
import * as styles from './PlayerModal.module.scss';

export default function PlayerModal({ data, ...props }) {
  return (
    <Modal {...props} containerMod="player" contentMod="player" closeButton={false}>
      {onClose => (
        <>
          <CloseButton className={styles.closeBtn} onClose={onClose} />
          <YoutubePlayer {...data} theme="modal" />
        </>
      )}
    </Modal>
  );
}
