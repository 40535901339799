import React from 'react';
import cn from 'classnames';

import { Button } from 'components/UI';
import { FormField, FormCheckbox } from 'components/forms';

import * as styles from './FormSubmit.module.scss';

export const FormSubmit = ({
  className,
  size = 'sm',
  title = 'Send',
  fullWidthMobile = true,
  disabled,
  nda,
}) => {
  return (
    <div className={cn(styles.submit, nda && styles.withNDA, className)}>
      {nda && <FormField className={styles.ndaField} Component={FormCheckbox} {...nda} />}
      <div className={styles.buttonWrap}>
        <Button
          className={styles.button}
          type="submit"
          size={size}
          fullWidthMobile={fullWidthMobile}
          disabled={disabled}
        >
          {title}
        </Button>
      </div>
    </div>
  );
};
