import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { Link } from '@landing/ui';

import BurgerBtn from './components/BurgerBtn';
import MobileMenu from './components/MobileMenu';
import AnchorMenu from './components/AnchorMenu';

import * as styles from './Header.module.scss';

export default function Header({ anchorLinks }) {
  const [headerFixed, setHeaderFixed] = useState(false);
  const [mobileMenuVisible, setActiveMobileMenu] = useState(false);

  const toggleMobileMenu = useCallback(() => {
    setActiveMobileMenu(!mobileMenuVisible);
  }, [mobileMenuVisible]);

  const handlerScroll = useCallback(() => {
    const scrollTop = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
    scrollTop > 20 ? setHeaderFixed(true) : setHeaderFixed(false);
  }, []);

  const onLinkClick = useCallback(() => {
    setActiveMobileMenu(false);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll, { passive: true });
    handlerScroll();

    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, []);

  return (
    <header className={cn(styles.header, headerFixed && styles.fixed)}>
      <div className={styles.layout}>
        <Link href="https://people.andersenlab.com/" className={styles.logoWrap}>
          <div
            className={cn(styles.logo, !headerFixed && !mobileMenuVisible && styles.whiteLogo)}
          />
        </Link>
        <div className={styles.mobilePanel}>
          <BurgerBtn
            fixed={mobileMenuVisible}
            active={mobileMenuVisible}
            onClick={toggleMobileMenu}
          />
          {mobileMenuVisible && <MobileMenu anchorLinks={anchorLinks} onLinkClick={onLinkClick} />}
        </div>
        <div className={styles.navigation}>
          <AnchorMenu anchorLinks={anchorLinks} />
        </div>
      </div>
    </header>
  );
}
