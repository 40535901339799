import React from 'react';
import { Container } from 'components/UI';
import { Title } from '@landing/ui';
import ProgramItems from './ProgramItems';

import * as styles from './EventProgram.module.scss';

export default function EventProgram({ title, subtitle, items }) {
  return (
    <Container id="program" className={styles.container} background="transparent">
      <div className={styles.layout}>
        <Title color="white">{title}</Title>
        <p className={styles.subtitle}>{subtitle}</p>
        <div className={styles.itemsWrapper}>
          <ProgramItems items={items} />
        </div>
      </div>
    </Container>
  );
}
