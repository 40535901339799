import React from 'react';
import cn from 'classnames';
import * as styles from './Checkbox.module.scss';

function Checkbox({ name = '', className = '', children, ...rest }) {
  return (
    <label className={cn(styles.checkbox, className)}>
      <input className={styles.input} type="checkbox" name={name} {...rest} />
      <span className={styles.square}></span>
      {children}
    </label>
  );
}

export default Checkbox;
