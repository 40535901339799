import React from 'react';
import cn from 'classnames';
import { Icon } from '@landing/ui';

import * as styles from './SliderButton.module.scss';

export function SliderButton({ className, handleClick, theme }) {
  return (
    <button
      className={cn(className, styles.wrapper, styles[theme])}
      onClick={handleClick}
      aria-label="Slider button"
    >
      <Icon className={styles.arrowIcon} name="arrow" />
    </button>
  );
}
