import React from 'react';
import cn from 'classnames';
import { SliderButton } from './SliderButton';

import * as styles from './SliderControl.module.scss';

export function SliderControl({ className, current, to, handleNext, handlePrev, theme }) {
  return (
    <div className={cn(styles.buttonsWrapper, className)}>
      <SliderButton handleClick={handlePrev} theme={theme} />
      {current && to && <div className={cn(styles.text, styles[theme])}>{`${current}/${to}`}</div>}
      <SliderButton className={styles.nextButton} handleClick={handleNext} theme={theme} />
    </div>
  );
}
