import { addMethod, string, object, boolean } from 'yup';

import { nonCorporateEmails } from './constatnts';

export const corporateValidation = value => {
  if (!/@.+\./.test(value)) return true;

  return !nonCorporateEmails.some(hostname => value.includes(hostname));
};

const phoneValidation = value => {
  if (!value) {
    return false;
  }

  return value.length >= 5 && value.length <= 18;
};

addMethod(string, 'checkCorporateEmail', function (message) {
  return this.test('checkCorporateEmail', message, function (value) {
    const { createError } = this;
    return corporateValidation(value) || createError({ path: 'notCorporateEmail', message });
  });
});

addMethod(string, 'checkPhone', function (message) {
  return this.test('checkPhone', message, function (value) {
    return phoneValidation(value);
  });
});

const corporateEmail = messages => {
  return string()
    .email(messages.required)
    .matches(/^[a-zA-Z0-9_-]{1,64}[-.-@a-zA-Z0-9]{1,63}?\.[a-z]{2,6}$/, {
      message: messages.required,
    })
    .checkCorporateEmail(messages.notCorporateEmail)
    .required(messages.required);
};

const phone = messages => {
  const message = 'Пожалуйста, введите номер телефона';

  return string()
    .required(messages || message)
    .checkPhone(messages || message);
};

export const corporateFormSchema = (messages, values) => {
  if (values.dateTime) {
    return object().shape({
      name: string().required(messages.name),
      email: string().email(messages.email.required).required(messages.email.required),
      phone: phone(messages.phone),
      message: string().required(messages.message),
      NDA: boolean(),
    });
  }
  return object().shape(
    {
      name: string().required(messages.name),
      email: string().when('phone', {
        is: value => phone(messages.phone).isValidSync(value),
        then: string().email(messages.email.required),
        otherwise: corporateEmail(messages.email),
      }),
      phone: string().when('email', {
        is: value => corporateEmail(messages.email).isValidSync(value),
        then: string(),
        otherwise: phone(messages.phone),
      }),
      message: string().required(messages.message),
      NDA: boolean(),
    },
    ['email', 'phone'],
  );
};
