import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { BasicSlider, SliderControl } from 'components/BasicSlider';

import * as styles from './Slider.module.scss';

export default function Slider({
  children,
  slides,
  theme,
  desktopSlidesToShow = 3,
  desktopSlidesToScroll = 3,
  tabletSlidesToShow = 3,
  tabletSlidesToScroll = 3,
}) {
  const sliderRef = useRef(null);
  const [current, setCurrent] = useState(1);
  const [paginationSlides, setPaginationSlides] = useState(0);
  const [paginationSlidesLength, setPaginationSlidesLength] = useState(0);
  const mobileSlidesToScroll = 1;

  const setCurrentIndex = useCallback(
    (prev, next) => {
      setCurrent(Math.ceil((next + 1) / paginationSlides));
    },
    [paginationSlides],
  );

  const handleNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, [sliderRef]);

  const handlePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, [sliderRef]);

  const setPaginationOptions = () => {
    if (sliderRef.current?.state.breakpoint === 544) {
      setPaginationSlides(mobileSlidesToScroll);
    } else if (sliderRef.current?.state.breakpoint === 1024) {
      setPaginationSlides(tabletSlidesToScroll);
    } else {
      setPaginationSlides(desktopSlidesToScroll);
    }
  };

  useEffect(() => {
    setPaginationSlidesLength(Math.ceil(slides.length / paginationSlides));
  }, [paginationSlides]);

  const settings = useMemo(
    () => ({
      speed: 1000,
      slidesToShow: desktopSlidesToShow,
      slidesToScroll: desktopSlidesToScroll,
      arrows: false,
      onInit: () => {
        setPaginationOptions();
      },
      onReInit: () => {
        setPaginationOptions();
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: tabletSlidesToShow,
            slidesToScroll: tabletSlidesToScroll,
          },
        },
        {
          breakpoint: 544,
          settings: {
            slidesToShow: 1,
            slidesToScroll: mobileSlidesToScroll,
            adaptiveHeight: true,
          },
        },
      ],
    }),
    [slides.length, current],
  );

  return (
    <>
      <BasicSlider ref={sliderRef} beforeChange={setCurrentIndex} {...settings}>
        {children}
      </BasicSlider>
      {slides.length > paginationSlides && (
        <div className={styles.controls}>
          <SliderControl
            current={current}
            to={paginationSlidesLength}
            handleNext={handleNext}
            handlePrev={handlePrev}
            theme={theme}
          />
        </div>
      )}
    </>
  );
}
