import React, { useMemo, useState, useRef, useEffect } from 'react';
import InputMask from 'react-input-mask';
import upperFirst from 'lodash/upperFirst';
import cn from 'classnames';

import { useClickOutside } from '../utils';

import { Icon, Tooltip } from '@landing/ui';

import * as styles from './Input.module.scss';

export const Input = ({
  field,
  theme,
  name,
  onChange,
  mask,
  prependedTooltip,
  tooltip,
  isTextarea,
  clearValue,
  upload,
  isTooltipVisible,
  errorsShakeHub,
  isErrorActive,
  domProps,
  className,
}) => {
  const [focused, setFocused] = useState(false);
  const [isOpenPrependedTooltop, setIsOpenPrependedTooltop] = useState(false);
  const inputRef = useRef();
  const prependedTooltipRef = useRef();
  const closeButtonRef = useRef();

  const InputTag = useMemo(() => {
    if (isTextarea) return 'textarea';
    if (mask) return InputMask;

    return 'input';
  }, [isTextarea]);

  useEffect(() => {
    errorsShakeHub.addEventListener('setFocus', onSetFocusHandler);

    return () => {
      errorsShakeHub.removeEventListener('setFocus', onSetFocusHandler);
    };
  }, [errorsShakeHub]);

  useClickOutside([inputRef], () => setFocused(false));
  useClickOutside([prependedTooltipRef], () => setIsOpenPrependedTooltop(false));

  const inputChangeHandler = e => {
    if (upload) onChange(e);
    else {
      if (isTextarea) autoGrow(e);

      onChange(e.target.value);
    }
  };

  const autoGrow = e => {
    const el = inputRef.current;

    if (el.scrollHeight > el.clientHeight + 1) {
      e.target.classList.add(styles.scrollbarShow);
    } else {
      e.target.classList.remove(styles.scrollbarShow);
    }

    el.style.height = el.scrollHeight > el.clientHeight ? `${el.scrollHeight}px` : '44px';
  };

  const style = {
    '--maxRows': domProps.maxrows,
    '--minRows': domProps.minrows,
  };

  const closeButtonMouseDownHandler = e => {
    onChange('');
    setFocused(false);
    e.stopPropagation();
  };

  const inputMouseDownHandler = e => {
    setFocused(true);
  };

  const onSetFocusHandler = ({ detail }) => {
    if (detail === name) {
      inputRef.current.focus();
      setFocused(true);
    }
  };

  return (
    <>
      <label className={cn(styles.label, className, theme && styles[`theme${upperFirst(theme)}`])}>
        {prependedTooltip && (
          <div
            className={styles.prependedTooltip}
            onClick={() => setIsOpenPrependedTooltop(true)}
            ref={prependedTooltipRef}
          >
            <Icon name="union" className={styles.prependedIcon} />
            {isOpenPrependedTooltop && (
              <Tooltip className={styles.tooltip} text={prependedTooltip} />
            )}
          </div>
        )}
        <InputTag
          className={cn(
            isErrorActive && styles.error,
            focused && styles.focused,
            prependedTooltip && styles.withPrependedTooltip,
          )}
          style={style}
          name={name}
          onInput={inputChangeHandler}
          onMouseDown={inputMouseDownHandler}
          {...(mask
            ? {
                ...mask,
                inputRef: ref => (inputRef.current = ref),
              }
            : { ref: inputRef })}
          {...field}
          {...domProps}
        />
        {clearValue && focused && (
          <div
            ref={closeButtonRef}
            className={styles.closeButton}
            onMouseDown={closeButtonMouseDownHandler}
          />
        )}
        {isTooltipVisible && focused && <Tooltip className={styles.tooltip} text={tooltip} />}
      </label>
    </>
  );
};
