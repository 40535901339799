import React from 'react';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import { Container } from 'components/UI';
import { Title } from '@landing/ui';
import Slider from 'components/Slider';
import Slide from './Slide';

import * as styles from './Interviews.module.scss';

export default function Interviews({ id, theme, title, subtitle, slides }) {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <Container
      id={id}
      className={cn(styles.container, theme && styles[theme])}
      background="transparent"
    >
      <div className={styles.layout}>
        <Title color="white">{title}</Title>
        <p className={styles.subtitle}>{subtitle}</p>
        <div className={cn(styles.sliderWrapper, 'defaultSlider')} ref={inViewRef}>
          {inView && (
            <Slider slides={slides}>
              {slides.map(slide => (
                <div key={slide.title} className={styles.slide}>
                  <Slide {...slide} />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </Container>
  );
}
