import React, { useEffect, useState } from 'react';
import Api from 'api';
import { preparePositionDescription } from './utils';
import { Button, CloseButton, List, Loader, Modal, Subtitle, Title } from '@landing/ui';
import * as styles from './VacancyModal.module.scss';

const Content = ({ items }) => {
  return (
    <div className={styles.desc}>
      {items?.map((el, i) => {
        switch (el.TYPE) {
          case 'text':
            return <p key={i}>{el.content}</p>;
          case 'list':
            return <List items={el.content} key={i}></List>;
          default:
            return '';
        }
      })}
    </div>
  );
};

export default function VacancyModal({ isOpen, closeModal, id, buttonText }) {
  const [positionDescription, setPositionDescription] = useState({});

  useEffect(() => {
    if (isOpen) {
      Api.getVacancy(id).then(data => {
        setPositionDescription(preparePositionDescription(data));
      });
    }
  }, [isOpen, id]);

  const handleClick = onClose => () => {
    const scrollTarget = document.getElementById('registration');

    if (scrollTarget) {
      const offsetTop =
        scrollTarget.getBoundingClientRect().top + document.documentElement.scrollTop;
      onClose();
      window.scroll({ top: offsetTop, behavior: 'smooth' });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModalCallback={closeModal}
      contentMod="virtualTour"
      theme="fullWidthMobile"
      closeButton={false}
      containerMod="xlarge"
    >
      {onClose =>
        Object.keys(positionDescription)?.length ? (
          <>
            <CloseButton onClose={onClose} className={styles.close} />
            <div className={styles.content}>
              <Title className={styles.title}>{positionDescription.title}</Title>
              {positionDescription.summary?.length > 0 && (
                <div className={styles.row}>
                  <Subtitle className={styles.name}>Summary</Subtitle>
                  <Content items={positionDescription.summary} />
                </div>
              )}
              {positionDescription.requirements?.length > 0 && (
                <div className={styles.row}>
                  <Subtitle className={styles.name}>Requirements</Subtitle>
                  <Content items={positionDescription.requirements} />
                </div>
              )}
              {positionDescription.desiredSkills?.length > 0 && (
                <div className={styles.row}>
                  <Subtitle className={styles.name}>Desired skills</Subtitle>
                  <Content items={positionDescription.desiredSkills} />
                </div>
              )}
              {positionDescription.weOffer?.length > 0 && (
                <div className={styles.row}>
                  <Subtitle className={styles.name}>We offer</Subtitle>
                  <Content items={positionDescription.weOffer} />
                </div>
              )}
              <footer className={styles.footer}>
                <Button onClick={handleClick(onClose)} theme="rounded" className={styles.btn}>
                  {buttonText}
                </Button>
              </footer>
            </div>
          </>
        ) : (
          <Loader />
        )
      }
    </Modal>
  );
}
