import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import * as styles from './Tabs.module.scss';

export default function Tabs({ filters, active, setActive, setStyles, theme }) {
  const [current, setCurrent] = useState(active);
  const tabRef = useRef([]);
  const lineRef = useRef([]);
  const scrollRef = useRef();
  const [lineStyles, setLineStyles] = useState({});

  const handleResizeWindow = () => {
    setLineStyles({
      '--width': `${tabRef.current[active].clientWidth}px`,
      '--left': `${tabRef.current[active].offsetLeft}px`,
    });
  };

  useEffect(() => {
    setCurrent(active);
    setTimeout(handleResizeWindow, 200);
    window.addEventListener('resize', handleResizeWindow);

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [active]);

  const handleChange = item => () => {
    setLineStyles({
      '--width': `${tabRef.current[item].clientWidth}px`,
      '--left': `${tabRef.current[item].offsetLeft}px`,
    });
    setStyles({ '--opacity': 0.1 });
    setCurrent(item);
    centeredButton(tabRef.current[item]);
  };

  const onTransitionEnd = () => {
    setActive(current);
    setStyles({ '--opacity': 1 });
  };

  const centeredButton = item => {
    try {
      const padding = 20;
      const filterWidth = scrollRef.current.clientWidth;
      const offset = item.offsetLeft + item.clientWidth / 2 - filterWidth / 2;
      scrollRef.current.scrollTo({
        left: offset + padding,
        behavior: 'smooth',
      });
    } catch (e) {
      throw new Error("Can't center active tab");
    }
  };

  return (
    <div className={cn(styles.buttonRow, styles[theme])} ref={scrollRef}>
      {filters.map(({ id, tabLabel }) => (
        <button
          key={id}
          ref={el => (tabRef.current[id] = el)}
          className={styles.button}
          onClick={handleChange(id)}
          disabled={active === id}
        >
          {tabLabel}
        </button>
      ))}
      <div
        ref={lineRef}
        className={styles.line}
        style={lineStyles}
        onTransitionEnd={onTransitionEnd}
      />
    </div>
  );
}
