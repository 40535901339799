import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { observer, modal as modalStore } from 'store';
import { Link } from '@landing/ui';
import { Button } from 'components/UI';

import * as styles from './AnchorMenu.module.scss';

function AnchorMenu({ anchorLinks, theme, onLinkClick }) {
  const [elementInView, setElementInView] = useState(null);
  const isButtonOutlined = theme !== 'dark' && theme !== 'mobileMenu';

  const handlerScroll = useCallback(() => {
    const scrollPosition = window.scrollY + 400;

    const selected = anchorLinks?.find(({ anchor }) => {
      const ele = document.getElementById(anchor);

      if (ele) {
        const { offsetBottom, offsetTop } = getDimensions(ele);
        return scrollPosition > offsetTop && scrollPosition < offsetBottom;
      } else {
        return false;
      }
    });

    if (selected && selected.anchor !== elementInView) {
      setElementInView(selected.anchor);
    } else {
      setElementInView(null);
    }
  }, [elementInView, anchorLinks]);

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, [anchorLinks]);

  const callModal = () => {
    modalStore.open('request-consultation');
  };

  return anchorLinks ? (
    <nav className={cn(styles.linkWrapper, theme && styles[theme])}>
      {anchorLinks?.map(({ anchor, alias, title }) => (
        <Link
          key={title}
          className={cn(styles.link, anchor === elementInView && styles.current)}
          href={alias}
          onClick={onLinkClick}
        >
          {title}
        </Link>
      ))}
      <Button
        className={styles.button}
        onClick={callModal}
        size="sm"
        theme={isButtonOutlined && 'outlined'}
      >
        Register
      </Button>
    </nav>
  ) : null;
}

function getDimensions(ele) {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export default observer(AnchorMenu);
