import { LOCAL_STORAGE_KEY, SESSION_STORAGE_KEY } from 'define';
import { modal as modalStore } from 'store';

export function getCDNUrl(path) {
  return `https://static.andersenlab.com/${path}`;
}

export const pushCustomEvent = (event, options = {}) => {
  const preparedEventName = event.replace(/[\s-]+/g, '_');
  window.dataLayer.push({
    event: `ndrsn_${preparedEventName}`,
    ...options,
  });
};

export const pushCookiePolicyEvent = () =>
  window.dataLayer.push({
    event: 'cookie_policy_update',
  });

export const pushCustomSubmitEvent = formID =>
  window.dataLayer.push({
    event: 'custom_submit_true',
    category: 'custom_submit_true',
    'gtm.elementId': `url: ${window.location.href}; formID: ${formID};`,
  });

export const getEntryPointsFromStorages = point => {
  if (point === 'first') {
    return { firstPoint: window.localStorage.getItem(LOCAL_STORAGE_KEY) };
  }

  if (point === 'session') {
    return { sessionPoint: window.sessionStorage.getItem(SESSION_STORAGE_KEY) };
  }

  return {
    firstPoint: window.localStorage.getItem(LOCAL_STORAGE_KEY),
    sessionPoint: window.sessionStorage.getItem(SESSION_STORAGE_KEY),
  };
};
