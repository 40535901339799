const CONSENT_COOKIE_NAME = 'consent_status';
const CONSENT_MODAL_ID = 'consentModal';

const getCookieOptions = () => {
  const expires = new Date();
  expires.setMonth(expires.getMonth() + 3);
  const domain = window.location.hostname === 'localhost' ? 'localhost' : `andersenlab.com`;

  return { expires, domain };
};

export { CONSENT_COOKIE_NAME, CONSENT_MODAL_ID, getCookieOptions };
