import React from 'react';
import { Link } from '@landing/ui';
import { Contacts } from 'components/UI';

import * as styles from './Footer.module.scss';

export default function Footer({ anchorLinks }) {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.layout}>
        <div className={styles.top}>
          <Contacts className={styles.contacts} />
          <div className={styles.navigation}>
            {anchorLinks?.map(({ alias, title }) => (
              <Link key={title} className={styles.link} href={alias}>
                {title}
              </Link>
            ))}
          </div>
        </div>
        <span className={styles.copyright}>{`© ${currentYear} Andersenlab.com`}</span>
      </div>
    </footer>
  );
}
