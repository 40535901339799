import React, { useContext, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { ErrorsShakeHubContext } from 'components/forms/utils';

import * as styles from './FormError.module.scss';

export const FormError = ({ value, active }) => {
  const [innerValue, setInnerValue] = useState();
  const [titleStyle, setTitleStyle] = useState({ '--width': 'fit-content', '--translateX': 0 });
  const errorRef = useRef();
  const titleRef = useRef();
  const [shake, setShake] = useState(false);
  const errorsShakeHub = useContext(ErrorsShakeHubContext);

  useEffect(() => {
    errorsShakeHub.addEventListener('shakeError', animate);

    return () => {
      errorsShakeHub.removeEventListener('shakeError', animate);
    };
  }, [errorsShakeHub, innerValue]);

  useEffect(() => {
    if (errorRef.current.clientWidth < titleRef.current.clientWidth) {
      setTitleStyle({
        '--width': 'auto',
        '--translateX': `${errorRef.current.clientWidth - titleRef.current.clientWidth}px`,
      });
    }
  }, [innerValue]);

  useEffect(() => {
    if (value && active && !innerValue) {
      setInnerValue(value);
      animate();
    }

    if (value && active && value !== innerValue) {
      setInnerValue(value);
    }

    if (!value) {
      setInnerValue(value);
    }
  }, [value, active, innerValue]);

  const animate = () => {
    setShake(true);
    setTimeout(() => setShake(false), 300);
  };

  return (
    <div
      className={cn(styles.error, shake && styles.shake, innerValue && styles.animation)}
      ref={errorRef}
    >
      <div className={styles.title} ref={titleRef} style={titleStyle}>
        {innerValue}
      </div>
    </div>
  );
};
