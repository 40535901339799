import React from 'react';
import { Title, Image, Link, Icon } from '@landing/ui';

import * as styles from './Slide.module.scss';

export default function Slide({ image, title, link, position, description }) {
  return (
    <div className={styles.slide}>
      <div className={styles.pictureWrapper}>
        <Image className={styles.picture} classImage={styles.img} src={image} alt={image.alt} />
      </div>
      <div className={styles.text}>
        <Link className={styles.link} href={link}>
          <span className={styles.linkText}>{title}</span>
          <Icon name="linkedin" className={styles.icon} />
        </Link>
        <p className={styles.position}>{position}</p>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
}
