import React from 'react';
import cn from 'classnames';
import { Title, Icon, Image, Link } from '@landing/ui';
import { useStateModal } from 'utils/hooks';
import { PlayerModal } from '@landing/modals';

import * as styles from './Slide.module.scss';

export default function Slide({ cover, title, description, duration, videoSrc, startDate, link }) {
  const { modalState, showModal, hideModal } = useStateModal();

  const slideWithLink = (
    <Link className={styles.item} href={link}>
      <div className={styles.pictureWrapper}>
        <Image className={styles.picture} classImage={styles.img} src={cover} alt={cover.alt} />
      </div>
      <div className={styles.text}>
        <Title className={styles.title} color="white">
          {title}
        </Title>
        <p className={styles.subtitle}>{description}</p>
      </div>
    </Link>
  );

  const slideWithVideo = (
    <>
      <div role="button" className={cn(styles.item, styles.video)}>
        <div className={styles.pictureWrapper} onClick={showModal}>
          <Image className={styles.picture} classImage={styles.img} src={cover} alt={cover.alt} />
          <Icon name="video-play-circle" className={styles.playIcon} />
          <div className={styles.time}>{duration}</div>
        </div>
        <div className={styles.text}>
          <Title className={styles.title} color="white">
            {title}
          </Title>
          <p className={styles.subtitle}>{description}</p>
          <p className={styles.date}>{startDate}</p>
        </div>
      </div>
      <PlayerModal isOpen={modalState.isOpen} closeModalCallback={hideModal} data={{ videoSrc }} />
    </>
  );

  return videoSrc ? slideWithVideo : slideWithLink;
}
