const _fetch = typeof window === 'undefined' ? require('node-fetch') : window.fetch;
const { serialize } = require('object-to-formdata');

class Request {
  constructor(apiLink) {
    this.apiLink = apiLink;
  }

  request(url, opts, body, failOnError) {
    const req = _fetch(`${this.apiLink}${url}`, {
      headers: {
        'Content-Type': 'application/json',
        'Cross-Origin-Resource-Policy': 'cross-origin',
        ...opts.headers,
      },
      ...opts,
      body,
    });

    return req.then(e => {
      if (failOnError && !e.ok) {
        throw new Error(e.message || 'error');
      }
      return e.json();
    });
  }

  get(url, headers, failOnError) {
    return this.request(
      url,
      {
        method: 'GET',
        headers,
      },
      null,
      failOnError,
    );
  }

  post(url, data, headers) {
    return this.request(
      url,
      {
        method: 'POST',
        headers,
      },
      data,
    );
  }

  postFormData(url, data, opts) {
    const gclid = window.localStorage.getItem('gclid');

    const withUtm = {
      ...data,
      gclid,
    };

    const body = serialize(withUtm, {
      indices: true,
      nullsAsUndefineds: true,
    });

    const req = _fetch(`${this.apiLink}${url}`, {
      method: 'POST',
      headers: {
        'Cross-Origin-Resource-Policy': 'cross-origin',
        Integration: 'site',
        'Accept-Language': 'en',
      },
      ...opts,
      body,
    });

    return req.then(res => {
      if (false === res.ok) {
        throw new Error('Error has occurred while sending form');
      }

      return res.json();
    });
  }
}

module.exports = Request;
