import { useEffect, useState } from 'react';

export const useIsSsr = () => {
  // we always start off in "SSR mode", to ensure our initial browser render
  // matches the SSR render
  const [isSsr, setIsSsr] = useState(true);

  useEffect(() => {
    // `useEffect` never runs on the server, so we must be on the client if
    // we hit this block
    setIsSsr(false);
  }, []);

  return isSsr;
};

export const useStateModal = () => {
  const [modalState, setModalState] = useState({ isOpen: false });

  const showModal = () => setModalState({ isOpen: true });
  const hideModal = () => setModalState({ isOpen: false });

  return { modalState, showModal, hideModal };
};

export const useClickOutside = (refs, handler) => {
  useEffect(() => {
    const targets = refs.map(el => el.current);

    const listener = e => {
      if (
        !targets.some(el => el?.contains && el.contains(e.target)) &&
        typeof handler === 'function'
      ) {
        handler();
      }
    };

    document.addEventListener('mouseup', listener);
    document.addEventListener('touchend', listener);
    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [refs, handler]);
};

export const useWindowResize = () => {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { width };
};
