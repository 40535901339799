import { appendScriptSrc } from 'utils/scripts';
import { loadedScriptsOnScroll, recaptchSrc } from 'define/scripts';
require('assets/scss/app.scss');
require('lazysizes');
require('lazysizes/plugins/attrchange/ls.attrchange');

const appendScriptsOnScroll = () => {
  appendScriptSrc(recaptchSrc, 'body', 'recaptcha');

  sessionStorage.setItem(loadedScriptsOnScroll, 'true');
};

export const onInitialClientRender = () => {
  window.addEventListener('scroll', appendScriptsOnScroll, { once: true });
};
