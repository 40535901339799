import React from 'react';

import { Title } from '@landing/ui';

import * as styles from './FormGrid.module.scss';

export const FormSection = ({ title, children }) => {
  return (
    <div className={styles.section}>
      <div className={styles.titleWrap}>
        <Title tag="h3">{title}</Title>
        {children.titleAppend}
      </div>
      {children.content || children}
    </div>
  );
};
