import React from 'react';
import { Container } from 'components/UI';
import { Title } from '@landing/ui';
import DesktopContent from './DesktopContent';
import MobileContent from './MobileContent';
import { useWindowResize } from 'utils/hooks';

import * as styles from './Thoughts.module.scss';

export default function Thoughts({ title, subtitle, items }) {
  const { width } = useWindowResize();
  return (
    <Container className={styles.container} background="gray" theme="stairs">
      <div className={styles.layout}>
        <Title>{title}</Title>
        <p className={styles.subtitle}>{subtitle}</p>
        {width > 544 ? (
          <DesktopContent items={items} className={styles.content} />
        ) : (
          <MobileContent items={items} className={styles.content} />
        )}
      </div>
    </Container>
  );
}
