import React from 'react';
import { Modal, CloseButton } from '@landing/ui';
import { observer, modal as modalStore } from 'store';

function PostSendModal({ className, Icon, children, modalID, theme, containerMod }) {
  const isOpen = modalStore.modalID === modalID;

  return (
    <Modal
      className={className}
      isOpen={isOpen}
      closeButton={false}
      animation="opacity"
      theme={theme ?? 'send'}
      containerMod={containerMod}
      closeModalCallback={modalStore.close}
    >
      {onClose => (
        <>
          {Icon && <Icon />}
          <CloseButton
            onClose={() => {
              onClose();
              modalStore.close();
            }}
          />
          {children}
        </>
      )}
    </Modal>
  );
}

export default observer(PostSendModal);
