// extracted by mini-css-extract-plugin
export var slide = "Slide-module--slide--dec33";
export var pictureWrapper = "Slide-module--pictureWrapper--ec5ad";
export var picture = "Slide-module--picture--620ca";
export var img = "Slide-module--img--f266a";
export var text = "Slide-module--text--bcc19";
export var link = "Slide-module--link--a7ac9";
export var linkText = "Slide-module--linkText--15530";
export var icon = "Slide-module--icon--0a91c";
export var position = "Slide-module--position--e4e93";
export var description = "Slide-module--description--935f5";