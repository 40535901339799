import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { Link } from '@landing/ui';
import * as styles from './AnchorMenu.module.scss';

export default function AnchorMenu({ anchorLinks, theme, onLinkClick }) {
  const [elementInView, setElementInView] = useState(null);

  const handlerScroll = useCallback(() => {
    const scrollPosition = window.scrollY + 100;

    const selected = anchorLinks?.find(({ anchor }) => {
      const ele = document.getElementById(anchor);

      if (ele) {
        const { offsetBottom, offsetTop } = getDimensions(ele);
        return scrollPosition > offsetTop && scrollPosition < offsetBottom;
      } else {
        return false;
      }
    });

    if (selected && selected.anchor !== elementInView) {
      setElementInView(selected.anchor);
    } else {
      setElementInView(null);
    }
  }, [elementInView, anchorLinks]);

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, [anchorLinks]);

  return anchorLinks ? (
    <nav className={cn(styles.linkWrapper, theme && styles[theme])}>
      {anchorLinks?.map(({ anchor, alias, title }) => (
        <Link
          key={title}
          className={cn(styles.link, anchor === elementInView && styles.current)}
          href={alias}
          onClick={onLinkClick}
        >
          {title}
        </Link>
      ))}
    </nav>
  ) : null;
}

function getDimensions(ele) {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}
