import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import Fuse from 'fuse.js';
import uf from 'lodash/upperFirst';

import { Icon, Tooltip } from '@landing/ui';
import { useClickOutside, useDropDown } from '../utils';

import * as styles from './Select.module.scss';

export const Select = ({
  field,
  className,
  placeholder,
  options,
  value,
  onChange,
  withSearch,
  withInput,
  notEmpty,
  theme,
  emptyOptionsMessage = 'Нет опций',
  name,
  isErrorActive,
  onClose,
  prependedTooltip,
}) => {
  const arrowUpRef = useRef(null);
  const arrowDownRef = useRef(null);
  const headRef = useRef(null);
  const menuRef = useRef(null);
  const listRef = useRef(null);
  const inputValueRef = useRef(false);
  const inputValueFocusRef = useRef(false);
  const prependedTooltipRef = useRef();

  const selected = value || field.value;

  const [isOpenPrependedTooltop, setIsOpenPrependedTooltop] = useState(false);

  const { close, toggle, isOpen } = useDropDown(
    headRef,
    menuRef,
    arrowUpRef,
    arrowDownRef,
    onClose,
  );

  const [search, setSearch] = useState('');

  useClickOutside([prependedTooltipRef], () => setIsOpenPrependedTooltop(false));

  useEffect(() => {
    if (notEmpty && selected.length < 1) {
      throw new RangeError('You should pass at least one value to Select component.');
    }

    if (withInput && withSearch) {
      throw new Error('Select with prop "withInput" can\'t be used with "withSearch" props.');
    }
  }, [notEmpty, withInput, withSearch]);

  useEffect(() => {
    if ((isMultiple() && selected.length < 1) || (!isMultiple() && selected === '')) {
      setSearch('');
    }
  }, [selected]);

  const getLabel = () => {
    if ((isMultiple() && selected.length === 0) || selected === '') {
      return placeholder;
    }

    if (isMultiple()) {
      return options
        .filter(option => selected.includes(option.value))
        .map(el => el.content)
        .join(', ');
    }

    return options.reduce((acc, cur) => {
      if (selected === cur.value) {
        return cur.content;
      }

      return acc;
    }, '');
  };

  const inputValueOnChange = (e, result) => {
    if (isMultiple()) {
      const values = result ?? inputValueRef.current.value.split(',');
      const prepared = [];
      const lastValue = values[values.length - 1];
      let isLastValueComplete = false;

      if (
        options.some(v => {
          if (lastValue) {
            return v.value === lastValue.trim();
          }

          return false;
        }) &&
        lastValue.charAt(lastValue.length - 1) === ' '
      ) {
        isLastValueComplete = true;
      }

      for (let i = 0; i < values.length; i++) {
        if (i === values.length - 1) {
          if (isLastValueComplete) {
            prepared.push(values[i].trim());
          } else {
            prepared.push(values[i].replace(/^\s+/, ''));
          }

          if (!options.some(v => v.value === values[i].trim())) {
            setSearch(values[i].replace(/^\s+/, ''));
          } else {
            setSearch('');
          }
        } else if (options.some(v => v.value === values[i].trim())) {
          prepared.push(values[i].trim());
        }
      }

      for (let i = 0; i < prepared.length; i++) {
        if (prepared[i] === '') {
          prepared.splice(i, 1);
        }
      }

      if (isLastValueComplete) {
        prepared.push(' ');
      }

      onChange(prepared);
    } else {
      const value = result ?? inputValueRef.current.value;
      onChange(value);
      setSearch(options.some(el => el.value === value) ? '' : value);
    }
  };

  const inputValueOnKeydown = e => {
    if (e.key.length === 1 && /[^a-zA-Zа-яА-Я-\s]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleSearch = e => setSearch(e.target.value);

  const headClickHandler = () => {
    if (inputValueFocusRef.current && isOpen) return;

    if (toggle()) {
      scrollToActiveValue();
    }
  };

  const scrollToActiveValue = () => {
    let firstActiveOption;
    let isActiveOptionVisible = false;

    Array.from(listRef.current.children).forEach(el => {
      const top = menuRef.current.scrollTop;
      const bottom = top + menuRef.current.offsetHeight;

      if (!firstActiveOption && el.getAttribute('data-active') === 'true') {
        firstActiveOption = el;
      }

      if (
        el.getAttribute('data-active') === 'true' &&
        el.offsetTop > top &&
        el.offsetTop + el.offsetHeight < bottom
      ) {
        isActiveOptionVisible = true;
      }

      if (!isActiveOptionVisible && firstActiveOption) {
        menuRef.current.scrollTop = firstActiveOption.offsetTop - 5;
      }
    });
  };

  const handleOptionClick = clicked => {
    let result = [];

    if (
      (notEmpty && isMultiple() && selected.length === 1 && clicked.value === selected[0]) ||
      (notEmpty && clicked.value === selected)
    ) {
      return;
    }

    if (isMultiple()) {
      result = selected.some(value => value === clicked.value)
        ? selected.filter(item => item !== clicked.value)
        : [...selected, clicked.value];
    } else {
      result = selected === clicked.value ? '' : clicked.value;
      setTimeout(() => {
        close();
      }, 220);
    }

    if (withInput) {
      inputValueOnChange(null, result);
    } else {
      onChange(result);
    }
  };

  const listContent = () => {
    let filteredOptions = options;

    if (search) {
      filteredOptions = new Fuse(options, { keys: ['value'] })
        .search(search.toLowerCase())
        .map(el => el.item);
    }

    const isOptionActive = option => {
      return isMultiple()
        ? selected.some(value => value === option.value)
        : selected === option.value;
    };

    if (filteredOptions?.length > 0) {
      return filteredOptions.map(option => (
        <div
          key={option.value}
          className={cn(
            styles.optionButton,
            isMultiple() && styles.multiple,
            isOptionActive(option) && styles.checked,
          )}
          onClick={() => handleOptionClick(option)}
          data-active={isOptionActive(option)}
        >
          <div className={styles.mark}></div>
          {option.content}
        </div>
      ));
    }

    if (search) {
      return <div className={styles.emptySearchResult}>Ничего не найдено</div>;
    }

    return <div className={styles.emptySearchResult}>{emptyOptionsMessage}</div>;
  };

  const isMultiple = () => {
    return Array.isArray(selected);
  };

  return (
    <div
      className={cn(
        className,
        styles.select,
        theme && styles[`theme${uf(theme)}`],
        isErrorActive && styles.error,
      )}
      tabIndex={0}
      name={name}
    >
      {prependedTooltip && (
        <div
          className={styles.prependedTooltip}
          onClick={() => setIsOpenPrependedTooltop(true)}
          ref={prependedTooltipRef}
        >
          <Icon name="union" className={styles.prependedIcon} />
          {isOpenPrependedTooltop && <Tooltip className={styles.tooltip} text={prependedTooltip} />}
        </div>
      )}
      <button
        ref={headRef}
        onClick={headClickHandler}
        className={cn(
          styles.button,
          selected.length > 0 && styles.selected,
          prependedTooltip && styles.withPrependedTooltip,
        )}
        type="button"
      >
        {withInput ? (
          <input
            className={styles.inputValue}
            type="text"
            onFocus={() => (inputValueFocusRef.current = true)}
            onBlur={() => (inputValueFocusRef.current = false)}
            placeholder={placeholder}
            value={isMultiple() ? selected.join(', ') : selected}
            onKeyDown={inputValueOnKeydown}
            onChange={inputValueOnChange}
            ref={inputValueRef}
          />
        ) : (
          <div className={styles.title}>{getLabel()}</div>
        )}
        <div className={styles.arrowWrap}>
          <div className={styles.iconWrap} ref={arrowUpRef}>
            <Icon name="chevron-down" />
          </div>
          <div className={styles.iconWrap} ref={arrowDownRef}>
            <Icon name="chevron-down" />
          </div>
        </div>
      </button>
      <div ref={menuRef} className={styles.dropdown}>
        {withSearch && (
          <div className={styles.searchRow}>
            <Icon name="loupe-left-side" className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search"
              className={styles.searchInput}
              value={search}
              onChange={handleSearch}
            />
          </div>
        )}
        <div className={styles.list} ref={listRef}>
          {listContent()}
        </div>
      </div>
    </div>
  );
};
