export const anchorLinks = [
  {
    anchor: 'team',
    alias: '#team',
    title: 'Speakers',
  },
  {
    anchor: 'program',
    alias: '#program',
    title: 'Agenda',
  },
  {
    anchor: 'about-organizer',
    alias: '#about-organizer',
    title: 'About the Organizer',
  },
];
