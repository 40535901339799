import React, { useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { Container, Markup, Button } from '@landing/ui';
import { observer, cookies as cookiesStore, modal as modalStore } from 'store';
import { CONSENT_COOKIE_NAME, CONSENT_MODAL_ID, getCookieOptions } from 'define/cookies';
import { setCookie, getCookie } from 'utils/cookie';
import { isRequestedFrom } from 'utils/localised';
import { euroMoneyRegion } from 'define/locale';
import { pushCookiePolicyEvent } from 'utils';

import data from './data.json';

import * as styles from './CookiesPolicy.module.scss';

const BANNER = 'banner';

function CookiesPolicy() {
  const [bannerTheme, setBannerTheme] = useState('gdpr');
  const content = data[bannerTheme];
  const [fullDescription, setFullDescription] = useState(false);
  const [consent, setConsent] = useState({ ad_storage: true, analytics_storage: true });

  const acceptCookies = () => {
    setCookie(CONSENT_COOKIE_NAME, JSON.stringify(consent), getCookieOptions());
    pushCookiePolicyEvent();
  };

  const onAccept = () => {
    if (bannerTheme === 'gdpr') {
      acceptCookies();
    }
    if (bannerTheme === 'small') {
      localStorage.setItem(BANNER, 'seen');
    }
    cookiesStore.setSeen(true);
  };

  const onManage = () => {
    modalStore.open(CONSENT_MODAL_ID);
    cookiesStore.setSeen(true);
  };

  useEffect(() => {
    const consentStatus = JSON.parse(getCookie(CONSENT_COOKIE_NAME));
    if (consentStatus) {
      setConsent(prev => ({ ...prev, ...consentStatus }));
    }

    if (isRequestedFrom(euroMoneyRegion)) {
      setBannerTheme('gdpr');
      return;
    }

    setBannerTheme('small');
  }, []);

  useEffect(() => {
    if (bannerTheme === 'gdpr' && !getCookie(CONSENT_COOKIE_NAME)) {
      cookiesStore.setSeen(false);
    } else if (bannerTheme === 'small') {
      if (!getCookie(CONSENT_COOKIE_NAME)) {
        acceptCookies();
      }
      if (!localStorage.getItem(BANNER)) {
        cookiesStore.setSeen(false);
      }
    }
  }, [bannerTheme]);

  const finalDescription = useMemo(() => {
    if (!fullDescription) {
      return content?.description.slice(0, content?.description.indexOf('\r'));
    }

    return content?.description;
  }, [content?.description, fullDescription]);

  return (
    <div className={cn(styles.wrapper, styles[bannerTheme], cookiesStore.seen && styles.hidden)}>
      <Container padding="Without" background="transparent">
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {content?.title && <p className={styles.title}>{content?.title}</p>}
            <div>
              <Markup tag="span" className={styles.text}>
                {finalDescription}
              </Markup>
              {bannerTheme === 'gdpr' && (
                <button
                  onClick={() => setFullDescription(!fullDescription)}
                  className={cn(styles.showMore, fullDescription && styles.active)}
                >
                  {!fullDescription ? content?.showMore : content?.hide}
                </button>
              )}
            </div>
          </div>
          {content?.manage && (
            <button className={styles.manageButton} onClick={onManage}>
              {content?.manage}
            </button>
          )}
          <Button
            className={cn(styles.button, !content?.manage && styles.withMargin)}
            onClick={onAccept}
            fullWidthMobile
          >
            {content?.accept}
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default observer(CookiesPolicy);
