import React from 'react';
import cn from 'classnames';

import * as styles from './Container.module.scss';

export default function Container({
  id,
  className,
  background = 'white',
  padding,
  theme,
  children,
}) {
  return (
    <section
      id={id}
      className={cn(
        styles.container,
        styles[`${background}BG`],
        padding && styles[`padding${padding}`],
        theme && styles[theme],
        className,
      )}
    >
      {children}
    </section>
  );
}
