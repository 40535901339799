import React, { useMemo, useRef, useEffect } from 'react';
import cn from 'classnames';
import InputMask from 'react-input-mask';

import { upperFirst } from 'utils';
import { useClickOutside } from 'utils/hooks';

import { Icon } from '../index';

import * as styles from './Input.module.scss';

export const Input = ({
  field,
  theme,
  name,
  onChange,
  mask,
  isTextarea,
  clearValue,
  upload,
  errorsShakeHub,
  isErrorActive,
  domProps,
  className,
  focused,
  setFocused,
  icon,
  disabled,
}) => {
  const inputRef = useRef();
  const closeButtonRef = useRef();

  const InputTag = useMemo(() => {
    if (isTextarea) return 'textarea';
    if (mask) return InputMask;

    return 'input';
  }, [isTextarea]);

  useEffect(() => {
    errorsShakeHub.addEventListener('setFocus', onSetFocusHandler);

    return () => {
      errorsShakeHub.removeEventListener('setFocus', onSetFocusHandler);
    };
  }, [errorsShakeHub]);

  useClickOutside([inputRef], () => setFocused(false));

  const inputChangeHandler = e => {
    if (upload) onChange(e);
    else {
      if (isTextarea) autoGrow(e);

      onChange(e.target.value);
    }
  };

  const autoGrow = e => {
    const el = inputRef.current;

    if (el.scrollHeight > el.clientHeight + 1) {
      e.target.classList.add(styles.scrollbarShow);
    } else {
      e.target.classList.remove(styles.scrollbarShow);
    }

    el.style.height = el.scrollHeight > el.clientHeight ? `${el.scrollHeight}px` : '44px';
  };

  const style = {
    '--maxRows': domProps.maxRows,
    '--minRows': domProps.minRows,
  };

  const closeButtonMouseDownHandler = e => {
    onChange('');
    setFocused(false);
    e.stopPropagation();
  };

  const inputMouseDownHandler = e => {
    setFocused(true);
  };

  const onSetFocusHandler = ({ detail }) => {
    if (detail === name) {
      inputRef.current.focus();
      setFocused(true);
    }
  };

  return (
    <>
      <label
        className={cn(
          styles.label,
          className,
          icon && styles.withIcon,
          theme && styles[`theme${upperFirst(theme)}`],
        )}
      >
        {icon && <Icon name={icon} className={styles.inputIcon} />}
        <InputTag
          className={cn(isErrorActive && styles.error, focused && styles.focused)}
          style={style}
          name={name}
          onInput={inputChangeHandler}
          onMouseDown={inputMouseDownHandler}
          disabled={disabled}
          {...(mask
            ? {
                ...mask,
                inputRef: ref => (inputRef.current = ref),
              }
            : { ref: inputRef })}
          {...field}
          {...domProps}
        />
        <span className={styles.placeholder}>{domProps.placeholder}</span>
        {clearValue && focused && (
          <div
            ref={closeButtonRef}
            className={styles.closeButton}
            onMouseDown={closeButtonMouseDownHandler}
          />
        )}
      </label>
    </>
  );
};
