import React from 'react';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import { Container, Button } from 'components/UI';
import { Title } from '@landing/ui';
import Slider from 'components/Slider';
import Slide from './Slide';

import * as styles from './Team.module.scss';

export default function Team({ title, slides }) {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <Container className={styles.container} background="gray" theme="stairs" id="team">
      <div className={styles.layout}>
        <Title>{title}</Title>
        <div className={cn(styles.sliderWrapper, 'defaultSlider')} ref={inViewRef}>
          {inView && (
            <Slider slides={slides} theme="white">
              {slides.map(slide => (
                <div key={slide.title} className={styles.slide}>
                  <Slide {...slide} />
                </div>
              ))}
            </Slider>
          )}
        </div>
        <div className={styles.alert}>
          <p className={styles.alertText}>
            Join Andersen's events! Apply as a speaker or sponsor and connect with top industry
            professionals
          </p>
          <Button
            tag="a"
            className={styles.alertButton}
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSc_sPGo0oFQFKqMViwV_tRY-6vLz3Lov1zK4OKZKVeJo4hlhA/viewform"
            fullWidthMobile
          >
            Become a Speaker
          </Button>
        </div>
      </div>
    </Container>
  );
}
