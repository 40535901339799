export const logos = [
  {
    src: 'https://static.andersenlab.com/andersenlab/new-andersensite/customers/emfit_white.svg',
    alt: 'Emfit',
  },
  {
    src: 'https://static.andersenlab.com/andersenlab/new-andersensite/cases/task-dent/logo/task-dent-white-logo.svg',
    alt: 'TaskDent',
  },
  {
    src: 'https://static.andersenlab.com/andersenlab/new-andersensite/customers/universkin-white.svg',
    alt: 'Universkin',
  },
];
