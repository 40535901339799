// extracted by mini-css-extract-plugin
export var popup = "Modal-module--popup--62543";
export var disable = "Modal-module--disable--522f1";
export var request = "Modal-module--request--bdaaf";
export var content = "Modal-module--content--7a02f";
export var send = "Modal-module--send--ec307";
export var sendOwlSoul = "Modal-module--sendOwlSoul--e81d0";
export var galleryOwlSoul = "Modal-module--galleryOwlSoul--d525a";
export var galleryOwlSoulVertical = "Modal-module--galleryOwlSoulVertical--4a030";
export var container = "Modal-module--container--1ae9b";
export var fullWidthMobile = "Modal-module--fullWidthMobile--3e990";
export var alignDisable = "Modal-module--alignDisable--1194c";
export var tooHigh = "Modal-module--tooHigh--6f4ae";
export var services = "Modal-module--services--610b2";
export var calculator = "Modal-module--calculator--fcfea";
export var player = "Modal-module--player--4c473";
export var large = "Modal-module--large--68565";
export var xlarge = "Modal-module--xlarge--b7628";
export var medium = "Modal-module--medium--473d1";
export var small = "Modal-module--small--ad910";
export var xs = "Modal-module--xs--5f346";
export var wide = "Modal-module--wide--929b3";
export var withAside = "Modal-module--withAside--25066";
export var fullScreenMobile = "Modal-module--fullScreenMobile--4876a";
export var consentModal = "Modal-module--consentModal--b00bb";
export var loading = "Modal-module--loading--bcff5";
export var opacity = "Modal-module--opacity--970d9";
export var transparent = "Modal-module--transparent--145d2";
export var active = "Modal-module--active--480ba";
export var show = "Modal-module--show--e8a34";
export var bottomToTop = "Modal-module--bottomToTop--4bde8";
export var topToBottom = "Modal-module--topToBottom--c608b";
export var left = "Modal-module--left--c1a63";
export var rightToLeft = "Modal-module--rightToLeft--717e8";
export var leftToRight = "Modal-module--leftToRight--a27f7";
export var paddingXL = "Modal-module--paddingXL--0d421";
export var paddingMD = "Modal-module--paddingMD--e810e";
export var workflow = "Modal-module--workflow--4f656";
export var indent = "Modal-module--indent--53d45";
export var withoutMargin = "Modal-module--withoutMargin--895d8";
export var withoutMarginMobile = "Modal-module--withoutMarginMobile--7c47f";
export var rounded = "Modal-module--rounded--d7373";
export var consentModalRounded = "Modal-module--consentModalRounded--88f4d";
export var virtualTour = "Modal-module--virtualTour--1b43e";
export var concept = "Modal-module--concept--bc702";
export var gallery = "Modal-module--gallery--bac97";