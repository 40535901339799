import React from 'react';
import cn from 'classnames';
import Markup from '../Markup';

import * as styles from './Checkbox.module.scss';

export const Checkbox = ({ field, name, className, onChange, content, domProps }) => {
  return (
    <label className={cn(styles.checkbox, className)}>
      <input
        onChange={e => onChange(e.target.checked)}
        className={styles.input}
        type="checkbox"
        name={name}
        checked={field.value}
        {...domProps}
      />
      <span className={styles.square}></span>
      <Markup tag="span" className={styles.content}>
        {content}
      </Markup>
    </label>
  );
};
