import React from 'react';
import PostSendModal from '../PostSendModal/PostSendModal';
import { Subtitle, Icon } from '@landing/ui';
import { defaultModalsId } from '../constants';

import * as styles from './SuccessModal.module.scss';

export default function SuccessModal() {
  return (
    <PostSendModal
      Icon={() => <Icon name="letter-success" className={styles.icon} />}
      modalID={defaultModalsId.successModal}
    >
      <div className={styles.wrapper}>
        <Subtitle className={styles.text}>
          {'Thank you for your interest!\n\rWe will contact you shortly.'}
        </Subtitle>
      </div>
    </PostSendModal>
  );
}
