import React from 'react';
import { List, Button, Markup, LazyImage } from '@landing/ui';
import * as styles from './Section.module.scss';

export default function Section({
  name,
  img,
  specialisations,
  requirements,
  plus,
  notification,
  linkText,
  button,
}) {
  return (
    <div className={styles.section}>
      <div className={styles.imgWrapper}>
        <LazyImage src={img} alt={name} className={styles.img} />
      </div>
      <div className={styles.infoWrapper}>
        {specialisations && (
          <>
            <p className={styles.title}>Specializations:</p>
            <p className={styles.subtitle}>{specialisations}</p>
          </>
        )}
        {requirements && (
          <>
            <p className={styles.title}>Requirements for candidates:</p>
            <List items={requirements} />
          </>
        )}
        {plus && (
          <>
            <p className={styles.title}>Nice-to-haves:</p>
            <List items={plus} />
          </>
        )}
        {notification && <div className={styles.notification}>{notification}</div>}
        {button && (
          <Button tag="a" href={button.link} fullWidthMobile>
            {button.text}
          </Button>
        )}
        {linkText && (
          <Markup tag="p" className={styles.subtitle}>
            {linkText}
          </Markup>
        )}
      </div>
    </div>
  );
}
