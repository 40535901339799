export function preparePositionDescription(response) {
  const clientDescription = response.ClientLanguages[0]?.ClientDescription || [];
  const description = response.description || [];

  return {
    title: response.title,
    summary: clientDescription.concat(description),
    requirements: response.requirements || [],
    desiredSkills: response.niceToHave || [],
    weOffer: response.weOffer || [],
  };
}
