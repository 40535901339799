// extracted by mini-css-extract-plugin
export var item = "Slide-module--item--545ae";
export var picture = "Slide-module--picture--c557d";
export var text = "Slide-module--text--c7635";
export var video = "Slide-module--video--8912c";
export var pictureWrapper = "Slide-module--pictureWrapper--ecf44";
export var playIcon = "Slide-module--playIcon--46ba5";
export var img = "Slide-module--img--62684";
export var time = "Slide-module--time--70b50";
export var title = "Slide-module--title--5c07b";
export var subtitle = "Slide-module--subtitle--7e1b5";
export var date = "Slide-module--date--b51b7";