import React, { useState, useCallback } from 'react';
import { Container, Title } from '@landing/ui';
import { DirectionCard } from '@landing/cards';
import { DirectionModal } from '@landing/modals';
import { useStateModal } from './hooks';
import * as styles from './Directions.module.scss';

export default function Directions({ title, items }) {
  const [state, setState] = useState();
  const { modalState, showModal, hideModal } = useStateModal();

  const callModal = useCallback(data => {
    setState(data);
    showModal();
  }, []);

  return (
    <Container id="who-are-we-waiting-for" padding="BottomNone">
      <div className={styles.wrapper}>
        <Title tag="h2" className={styles.title}>
          {title}
        </Title>
        <div className={styles.layout}>
          {items.map(item => (
            <DirectionCard
              key={item.title}
              title={item.title}
              handleClick={() => callModal(item.data)}
              {...item}
            />
          ))}
        </div>
      </div>
      <DirectionModal isOpen={modalState.isOpen} closeModalCallback={hideModal} {...state} />
    </Container>
  );
}
