import React from 'react';
import cn from 'classnames';
import { Icon } from '@landing/ui';
import { phone, email } from 'define';

import * as styles from './Contacts.module.scss';

function Contacts({ className, theme }) {
  return (
    <div className={cn(className, styles.wrapper, theme && styles[theme])}>
      <a href={`tel:${phone}`} className={styles.contact}>
        <Icon name="phone" className={styles.icon} />
        <span className={styles.contactText}>{phone}</span>
      </a>
      <a href={`mailto:${email}`} className={styles.contact}>
        <Icon name="email" className={styles.icon} />
        <span className={styles.contactText}>{email}</span>
      </a>
    </div>
  );
}

export default Contacts;
