import React from 'react';
import cn from 'classnames';

import * as styles from './Tooltip.module.scss';

export default function Tooltip({ text, children, className, textClassName }) {
  return (
    <section className={cn(styles.container, className)}>
      {children}
      {text && <p className={textClassName}>{text}</p>}
      <span className={styles.triangle}></span>
    </section>
  );
}
