import React from 'react';
import { Container, Grid, GridItem, Link, Title } from '@landing/ui';
import SocialButtons from './SocialButtons';
import Copyright from './Copyright';

import * as styles from './Footer.module.scss';

export default function Footer({ socialNetworks, offices, allOffices, companyLinks = false }) {
  return (
    <footer id="about-andersen">
      <Container padding="Vertical">
        <div className={styles.layout}>
          <Title>Мир Андерсен</Title>
          <div className={styles.socialWrapper}>
            <SocialButtons items={socialNetworks} />
          </div>
          <div className={styles.offices}>
            <Grid
              xsm={{ cols: 1 }}
              sm={{ cols: 1 }}
              md={{ cols: 3 }}
              lg={{ cols: 3 }}
              xl={{ cols: 3 }}
              gutterY="20px"
            >
              {offices.map(({ title, description, link }) => (
                <GridItem key={title}>
                  <div className={styles.officeItem}>
                    {title && <p className={styles.officeTitle}>{title}</p>}
                    {description && <p className={styles.officeDescription}>{description}</p>}
                    {link && (
                      <Link href={link.url} target="_blank" className={styles.officeLink}>
                        {link.title}
                      </Link>
                    )}
                  </div>
                </GridItem>
              ))}
            </Grid>
            <div className={styles.officeLinkWrapper}>
              {allOffices && (
                <Link href={allOffices.url} target="_blank" className={styles.officeLink}>
                  {allOffices.title}
                </Link>
              )}
            </div>
            {companyLinks && (
              <div className={styles.companyLinks}>
                <a target="_blank" href="https://people.andersenlab.com/about_company.pdf">
                  Презентация о компании
                </a>{' '}
                |{' '}
                <a target="_blank" href="https://andersenlab.com/">
                  Сайт компании{' '}
                </a>
              </div>
            )}
          </div>
        </div>
      </Container>
      <Copyright />
    </footer>
  );
}
