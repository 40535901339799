import React from 'react';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import Slider from 'components/Slider';
import Card from './Card';

import * as styles from './MobileContent.module.scss';

export default function MobileContent({ items, className }) {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div className={cn(styles.content, className, 'defaultSlider')} ref={inViewRef}>
      {inView && (
        <Slider slides={items} theme="white">
          {items.map(item => (
            <div key={item.title} className={styles.item}>
              <Card {...item} />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}
