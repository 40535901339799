export const getBuildEnvironment = () => {
  return process.env.GATSBY_BUILD_ENV;
};

export const getIsSsr = () => {
  return process.env.NODE_ENV === 'production';
};

export const getApiUrl = () => {
  const buildEnv = getBuildEnvironment();

  if (buildEnv === 'production') {
    return 'https://asite-api.andersenlab.com';
  }

  return 'https://dev-asite-api.andersenlab.com';
};
