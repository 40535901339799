import React from 'react';
import cn from 'classnames';
import * as styles from './Item.module.scss';

export default function Item({ Tag = 'article', children, colSpan, hide = false }) {
  const style = {
    '--colSpan': colSpan,
  };

  return (
    <Tag
      className={cn(styles.gridItems, colSpan && styles.grow, hide && styles.hidden)}
      style={style}
    >
      {children}
    </Tag>
  );
}
