// extracted by mini-css-extract-plugin
export var header = "Header-module--header--1b646";
export var fixed = "Header-module--fixed--e1e68";
export var layout = "Header-module--layout--a6d3f";
export var logoWrap = "Header-module--logoWrap--c8d39";
export var logo = "Header-module--logo--84e3d";
export var whiteLogo = "Header-module--whiteLogo--9c07f";
export var navigation = "Header-module--navigation--9746d";
export var item = "Header-module--item--84724";
export var mobilePanel = "Header-module--mobilePanel--81516";
export var hiddenMg = "Header-module--hiddenMg--35480";