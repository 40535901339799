import React from 'react';
import { Modal, Title, CloseButton } from '@landing/ui';
import Section from './Section';
import * as styles from './DirectionsModal.module.scss';

export default function DirectionsModal({ isOpen, closeModalCallback, title, ...props }) {
  return (
    <Modal
      isOpen={isOpen}
      closeModalCallback={closeModalCallback}
      closeButton={false}
      containerMod="xlarge"
    >
      {onClose => (
        <>
          <CloseButton onClose={onClose} />
          <section className={styles.layout}>
            <Title tag="h2" size="h3" className={styles.title}>
              {title}
            </Title>
            <Section {...props} />
          </section>
        </>
      )}
    </Modal>
  );
}
