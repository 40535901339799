import React from 'react';
import cn from 'classnames';
import { Icon, Markup } from '@landing/ui';

import * as styles from './SearchInput.module.scss';

const SearchInput = ({
  className,
  theme,
  placeholder,
  search,
  results,
  clearSearch,
  handleSearch,
  handleItemClick,
  handleKeyDown,
  showList,
  loading,
}) => {
  return (
    <div className={cn(styles.wrapper, className, theme && styles[theme])}>
      <div className={cn(styles.searchRow, Boolean(search) && styles.active)}>
        <Icon
          name="loupe-left-side"
          className={cn(styles.searchIcon, Boolean(search) && styles.active)}
          onClick={() => handleItemClick(search)}
        />
        {search && <Icon name="close" className={styles.closeIcon} onClick={clearSearch} />}
        <input
          type="text"
          maxLength="48"
          placeholder={placeholder}
          className={cn(styles.searchInput, Boolean(search) && styles.active)}
          value={search}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          disabled={loading}
        />
      </div>
      {showList && search && (
        <div className={cn(styles.listWrapper, results?.length <= 5 && styles.withoutPadding)}>
          <ul className={styles.list}>
            {results?.length > 0 ? (
              results.map(({ value, label }) => (
                <Markup
                  tag="li"
                  key={value}
                  className={styles.listItem}
                  onClick={() => handleItemClick(value)}
                >
                  {label}
                </Markup>
              ))
            ) : (
              <div className={styles.noResult}>Запрашиваемые вакансии не найдены</div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
export default SearchInput;
