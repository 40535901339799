import React from 'react';
import cn from 'classnames';

import { ThemeContext } from 'components/forms/utils';

import * as styles from './FormGrid.module.scss';

export const FormGrid = ({ children, submitHandler, theme, id, formName, className, ...other }) => {
  return (
    <form
      className={cn(styles.formGrid, className)}
      onSubmit={submitHandler}
      id={id}
      name={formName}
      noValidate={true}
      {...other}
    >
      <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
    </form>
  );
};
