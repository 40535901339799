import React from 'react';
import { logos } from 'define/modalAside';
import { LazyImage } from '@landing/ui';

import * as styles from './Logos.module.scss';

export default function Logos() {
  return (
    <div className={styles.logosWrapper}>
      {logos.map(({ src, alt }) => (
        <LazyImage key={alt} src={src} alt={alt} className={styles.logo} />
      ))}
    </div>
  );
}
