import React from 'react';
import cn from 'classnames';
import * as styles from './Loader.module.scss';

export default function Loader({ theme, background, duration }) {
  const style = {
    '--d': duration,
  };
  return (
    <div className={cn(styles.wrap, theme && styles[theme], background && styles[background])}>
      <div className={styles.loader}>
        <div className={styles.item} style={style}></div>
        <div className={styles.item} style={style}></div>
        <div className={styles.item} style={style}></div>
        <div className={styles.item} style={style}></div>
      </div>
    </div>
  );
}
