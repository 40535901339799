import React from 'react';
import cn from 'classnames';
import upperFirst from 'lodash/upperFirst';
import { Link, Loader } from '../../index';
import * as styles from './Button.module.scss';

/**
 * @description используется для унификации кнопок на сайте
 * @param {String} size ширина кнопки
 * @param {String} theme внешний вид кнопки
 * @param {String} textColor цвет текста
 * @param {Boolean} fullWidth задаёт 100% ширину кнопки
 * @param {Boolean} fullWidthMobile задаёт 100% ширину кнопки для мобильных экранов
 * @param {Boolean} disabled вид кнопки при disabled
 * @param {Node} children наследники
 * @param {String} className уникальные стили
 * @param {String} tag используемый тег
 * @param {Boolean} isSubmitting проверка на нажатие кнопки
 * @param {Array} other другие props
 */
function Button({
  size = 'md',
  theme = '',
  textColor = '',
  fullWidth,
  fullWidthMobile,
  disabled,
  children,
  className,
  tag = 'button',
  isSubmitting,
  ...other
}) {
  const Wrapper = tag === 'a' ? Link : tag;

  return (
    <Wrapper
      className={cn(
        styles.button,
        size && styles[`size${size.toUpperCase()}`],
        theme && styles[`theme${upperFirst(theme)}`],
        textColor && styles[`textColor${upperFirst(textColor)}`],
        fullWidth && styles.fullWidth,
        fullWidthMobile && styles.fullWidthMobile,
        disabled && styles.disabled,
        className,
      )}
      disabled={disabled}
      {...other}
    >
      {isSubmitting ? <Loader theme="button" duration="2" /> : children}
    </Wrapper>
  );
}

export default Button;
