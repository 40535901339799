import { createContext } from 'react';

import Api from 'api';
import { getEntryPointsFromStorages } from 'utils';
import { showModal } from 'components/Modals';

export const createInitialValues = fields => {
  const result = {};

  for (const fieldKey in fields) {
    if (fields[fieldKey].initialValue === undefined) {
      throw new TypeError(
        `The settings object of field "${fieldKey}" must contain "initialValue" property!`,
      );
    }

    result[fieldKey] = fields[fieldKey].initialValue;
  }

  return result;
};

export const createFields = (fields, nda) => {
  const result = {};
  const fieldsArray = Object.entries(fields);

  for (let i = 0; i < fieldsArray.length; i++) {
    result[fieldsArray[i][0]] = {
      ...fieldsArray[i][1],
      name: fieldsArray[i][0],
      order: i,
    };
  }

  if (nda) {
    result.nda = {
      name: 'nda',
      initialValue: Boolean(nda.initialValue),
      top: Boolean(nda.top),
      order: fieldsArray.length,
    };
  }

  return result;
};

export const submitHandler = async ({
  id,
  formName,
  values,
  handler,
  onSuccess,
  successModal,
  errorModal,
  onError,
  onResponse,
  reset: { reset, resetHandler },
  setStatus,
  additionalFormData,
  getAdditionalFormData,
  withoutCaptchaToken,
  pushSubmitEvent,
}) => {
  let data = {
    form: formName,
    ...values,
    ...additionalFormData,
    ...getEntryPointsFromStorages(),
    lastPoint: window.location.href,
  };

  if (typeof getAdditionalFormData === 'function') {
    data = { ...data, ...getAdditionalFormData() };
  }

  if (!withoutCaptchaToken) {
    data.captchaToken = await Api.getCaptchaToken();
  }

  try {
    await handler(data);

    if (typeof onSuccess === 'function') {
      onSuccess();
    }

    if (typeof onResponse === 'function') {
      onResponse();
    }

    if (typeof pushSubmitEvent === 'function') {
      pushSubmitEvent(formName);
    }

    if (successModal) {
      showModal(successModal, successModal === 'SuccessModal' && { ...values });
    }

    if (reset) {
      resetHandler();
    }

    setStatus('success');
  } catch (e) {
    if (typeof onError === 'function') {
      onError();
    }

    if (typeof onResponse === 'function') {
      onResponse();
    }

    if (errorModal) {
      showModal(errorModal);
    }

    setStatus();
  }
};

export const findFirstFieldWithError = (fields, errors) => {
  let min = null;
  let targetFieldName = null;
  const fieldsWithError = Object.keys(errors);

  for (const fieldKey in fields) {
    if (fieldsWithError.includes(fieldKey)) {
      if (min === null || min > fields[fieldKey].order) {
        min = fields[fieldKey].order;
        targetFieldName = fieldKey;
      }
    }
  }

  return targetFieldName;
};

export const filterValidationRules = rules => {
  const newRules = {};

  for (const rulesKey in rules) {
    if (document.querySelector(`[name=${rulesKey}]`)) {
      newRules[rulesKey] = rules[rulesKey];
    }
  }

  return newRules;
};

export const ErrorsShakeHubContext = createContext();
export const ThemeContext = createContext();
