// extracted by mini-css-extract-plugin
export var wrapper = "CookiesPolicy-module--wrapper--f291d";
export var hidden = "CookiesPolicy-module--hidden--10292";
export var small = "CookiesPolicy-module--small--578e3";
export var container = "CookiesPolicy-module--container--3ac2e";
export var text = "CookiesPolicy-module--text--b12f9";
export var contentWrapper = "CookiesPolicy-module--contentWrapper--c9ed1";
export var content = "CookiesPolicy-module--content--fecc7";
export var title = "CookiesPolicy-module--title--946d9";
export var button = "CookiesPolicy-module--button--259ad";
export var manageButton = "CookiesPolicy-module--manageButton--d8498";
export var showMore = "CookiesPolicy-module--showMore--cbb76";
export var withMargin = "CookiesPolicy-module--withMargin--964c7";
export var active = "CookiesPolicy-module--active--3386b";