import React from 'react';
import { Link as LinkOriginal } from 'gatsby';

function Link({
  className,
  href,
  children,
  onClick,
  target = '_blank',
  rel = 'noreferrer noopener noindex nofollow',
  innerRef,
  ...other
}) {
  const linkClickHandler = e => {
    if (typeof onClick === 'function') {
      onClick();
    }

    let anchor = href.match(/#.+$/g);
    let scrollTarget = null;

    if (anchor && anchor.length > 0) {
      anchor = anchor[0].replace(/\?.+$/, '');
      scrollTarget = document.querySelector(anchor);
    }

    if (scrollTarget) {
      e.preventDefault();
      scrollTarget.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (href.startsWith('https://')) {
    return (
      <a
        className={className}
        onClick={linkClickHandler}
        href={href}
        target={target}
        rel={rel}
        ref={innerRef}
        {...other}
      >
        {children}
      </a>
    );
  }

  return (
    <LinkOriginal onClick={linkClickHandler} className={className} to={href} {...other}>
      {children}
    </LinkOriginal>
  );
}

export default React.memo(Link);
