import React from 'react';
import cn from 'classnames';

import * as styles from './FormGrid.module.scss';

export const FormRow = ({ className, sizes = [], children }) => {
  return (
    <div
      className={cn(
        styles.row,
        sizes.map(el => styles[el]),
        className,
      )}
    >
      {children}
    </div>
  );
};
