import { makeAutoObservable } from 'mobx';

export default class Cookies {
  seen = true;

  constructor() {
    makeAutoObservable(this);
  }

  setSeen = status => {
    this.seen = status;
  };
}
