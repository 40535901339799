import React, { useState } from 'react';
import cn from 'classnames';
import { Icon, CollapseEl } from '@landing/ui';
import ModalAside from 'components/ModalAside';

import * as styles from './MobileContacts.module.scss';

export default function MobileContacts({ className, modalAsideData }) {
  const [mobileContactsOpen, setMobileContactsOpen] = useState(false);

  const handleToggle = () => {
    const nextValue = !mobileContactsOpen;
    setMobileContactsOpen(nextValue);
  };

  return (
    <div className={cn(styles.mobileContacts, className)}>
      <button
        className={cn(styles.toggleButton, mobileContactsOpen && styles.active)}
        onClick={handleToggle}
      >
        <span>Contact us</span>
        <div className={styles.arrow}>
          <Icon className={styles.arrowIcon} name="select-arrow" />
        </div>
      </button>
      <CollapseEl toggle={mobileContactsOpen}>
        <ModalAside asideData={modalAsideData} theme="collapsible" />
      </CollapseEl>
    </div>
  );
}
