export const lockUnlockPage = {
  lock: () => {
    document.documentElement.setAttribute('style', 'overflow: hidden;');
    document.body.setAttribute('style', 'overflow-y: scroll;');
  },
  unlock: () => {
    document.documentElement.setAttribute('style', '');
    document.body.setAttribute('style', '');
  },
};
