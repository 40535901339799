import React, { lazy, Suspense } from 'react';
import { Container } from 'components/UI';
import { Title, Loader } from '@landing/ui';
import { useIsSsr } from 'utils/hooks';

import * as styles from './ContactUs.module.scss';

const CorporateForm = lazy(() => import('../CorporateForm'));
const Animation = lazy(() => import('components/UI/Animation'));

export default function ContactUs({ title }) {
  const isSsr = useIsSsr();
  return (
    <Container id="contactUs" className={styles.container} background="transparent">
      <div className={styles.layout}>
        <div className={styles.content}>
          <Title className={styles.title} color="white">
            {title}
          </Title>
        </div>
        <div className={styles.formWrapper}>
          {!isSsr && (
            <Suspense fallback={<Loader theme="fullHeight" />}>
              <CorporateForm captchaInfo={true} />
            </Suspense>
          )}
        </div>
        {!isSsr && (
          <Suspense fallback={null}>
            <Animation preserveAspectRatio className={styles.animation} name="about" />
          </Suspense>
        )}
      </div>
    </Container>
  );
}
