import React from 'react';
import cn from 'classnames';
import Card from './Card';

import * as styles from './DesktopContent.module.scss';

export default function DesktopContent({ items, className }) {
  return (
    <div className={cn(styles.content, className)}>
      {items.map(item => (
        <Card {...item} className={styles.item} key={item.title} />
      ))}
    </div>
  );
}
