import React from 'react';
import cn from 'classnames';

import * as styles from './FormCheckbox.module.scss';

export const FormCheckbox = ({ field, className, onChange, content, domProps }) => {
  return (
    <label className={cn(styles.checkbox, className)}>
      <input
        onChange={e => onChange(e.target.checked)}
        className={styles.input}
        type="checkbox"
        name={field.name}
        checked={field.value}
        {...domProps}
      />
      <span className={styles.square}></span>
      <div className={styles.contentWrapper}>
        <span>{content}</span>
      </div>
    </label>
  );
};
