import React from 'react';
import cn from 'classnames';

import * as styles from './Switcher.module.scss';

export default function Switcher({ name, checked, unchecked, active, onChange, theme }) {
  return (
    <label className={cn(styles.container, theme && styles[theme])}>
      {checked && <span className={styles.label}>{checked}</span>}
      <input
        type="checkbox"
        name={name}
        checked={active}
        className={styles.input}
        onChange={onChange}
      />
      <span className={styles.switcher}></span>
      {unchecked && <span className={styles.label}>{unchecked}</span>}
    </label>
  );
}
