import React, { lazy, Suspense, useEffect } from 'react';
import { Title, Modal, CloseButton, Loader } from '@landing/ui';
import { observer, modal as modalStore } from 'store';
import ModalAside from 'components/ModalAside';
import { appendScriptSrc } from 'utils/scripts';
import { recaptchSrc } from 'define/scripts';
import { useIsSsr } from 'utils/hooks';
import MobileContacts from './MobileContacts';

import * as styles from './RequestConsultation.module.scss';

function RequestConsultation({
  animation = 'show',
  modalID = 'request-consultation',
  commentPlaceholder,
  withAgreement,
  withNda,
  modalAsideData,
}) {
  const isSsr = useIsSsr();
  const isOpen = modalStore.modalID === modalID;
  const { initialIndustry, getAdditionalFormData } = modalStore.modalProps;

  useEffect(() => {
    const scriptLoaded = document.getElementById('recaptcha');

    if (isOpen && !scriptLoaded) {
      appendScriptSrc(recaptchSrc, 'body', 'recaptcha');
    }
  }, [isOpen]);

  if (isOpen) {
    const CorporateForm = lazy(() => import('components/CorporateForm'));

    return (
      <Modal
        isOpen={isOpen}
        closeModalCallback={modalStore.close}
        closeButton={false}
        containerMod="withAside"
        contentMod="noPadding"
        animation={animation}
      >
        {onClose => (
          <div className={styles.container}>
            <div className={styles.formWrap}>
              <Title className={styles.title} color="white">
                Secure your seat now
              </Title>
              <MobileContacts className={styles.desktopHidden} modalAsideData={modalAsideData} />
              <CloseButton onClose={onClose} theme="dark" />
              {!isSsr && (
                <Suspense
                  fallback={
                    <div className={styles.loaderWrap}>
                      <Loader theme="fullHeight" />
                    </div>
                  }
                >
                  <CorporateForm
                    closeModal={onClose}
                    commentPlaceholder={commentPlaceholder}
                    captchaInfo={true}
                    captchaMinHidden={false}
                    inModal={true}
                    withAgreement={withAgreement}
                    withNda={withNda}
                    initialIndustry={initialIndustry}
                    getAdditionalFormData={getAdditionalFormData}
                  />
                </Suspense>
              )}
            </div>
            <ModalAside className={styles.mobileHidden} asideData={modalAsideData} />
          </div>
        )}
      </Modal>
    );
  }
  return null;
}

export default observer(RequestConsultation);
