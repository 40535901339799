import React, { useState, useCallback } from 'react';
import { Container, Title, Accordion, Markup } from '@landing/ui';
import * as styles from './Faq.module.scss';

export default function Faq({ title, accordionInfo }) {
  const [activeSection, setActiveSection] = useState(0);

  const handleClick = useCallback(
    id => {
      if (activeSection === id) {
        setActiveSection(null);
      } else {
        setActiveSection(id);
      }
    },
    [activeSection],
  );

  return (
    <Container id="faq" padding="TopNone">
      <div className={styles.layout}>
        <Title tag="h2">{title}</Title>
        <div className={styles.wrapper}>
          {accordionInfo.map(({ title, description }, i) => (
            <Accordion
              key={i}
              id={i}
              title={title}
              theme="faq"
              isOpened={activeSection === i}
              onClick={() => handleClick(i)}
            >
              <Markup className={styles.description}>{description}</Markup>
            </Accordion>
          ))}
        </div>
      </div>
    </Container>
  );
}
