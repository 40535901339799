import React, { useCallback, useState, useMemo } from 'react';
import cn from 'classnames';
import { Icon } from '@landing/ui';
import * as styles from './SelectGroup.module.scss';

export default function SelectGroup({ options, label, onSelect, selected, className, withSearch }) {
  const optionsReady = useMemo(
    () =>
      options.map(item => {
        if (item.value) {
          return item;
        }

        return {
          value: item,
          label: item,
        };
      }),
    [options],
  );

  const [search, setSearch] = useState('');

  const handleSearch = useCallback(e => setSearch(e.target.value), []);

  const clearSearch = useCallback(() => setSearch(''), []);

  const handleItemClick = clicked => {
    const result = selected.some(({ value }) => value === clicked.value)
      ? selected.filter(item => item.value !== clicked.value)
      : [...selected, clicked];
    onSelect(result);
  };

  return (
    <div className={cn(className, styles.select)}>
      <div className={styles.layout}>
        <p className={styles.label}>{label}</p>
        {withSearch && (
          <div className={styles.searchRow}>
            {!search ? (
              <Icon name="loupe-left-side" className={styles.searchIcon} />
            ) : (
              <Icon name="close" className={styles.closeIcon} onClick={clearSearch} />
            )}
            <input
              type="text"
              maxLength="32"
              placeholder="Начните вводить"
              className={styles.searchInput}
              value={search}
              onChange={handleSearch}
            />
          </div>
        )}
        <ul className={styles.list}>
          {optionsReady.length ? (
            optionsReady
              .filter(item => item.value.toLowerCase().includes(search.toLowerCase()))
              .map(item => (
                <li key={item.value}>
                  <button
                    className={cn(
                      styles.itemButtonMultiple,
                      selected.some(({ value }) => value === item.value) && styles.checked,
                      item.count === 0 && styles.noItems,
                    )}
                    onClick={() => handleItemClick(item)}
                    disabled={item.count === 0}
                  >
                    {item.label}
                    {Object.keys(item).includes('count') && (
                      <span className={cn(styles.count, !withSearch && styles.withoutSearch)}>
                        {item.count}
                      </span>
                    )}
                  </button>
                </li>
              ))
          ) : (
            <div className={styles.noFilters}>Нет доступных фильтров</div>
          )}
        </ul>
      </div>
    </div>
  );
}
