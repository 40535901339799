import React from 'react';
import cn from 'classnames';
import upperFirst from 'lodash/upperFirst';
import { Link, Loader } from '@landing/ui';

import * as styles from './Button.module.scss';

function Button({
  size = 'md',
  theme = '',
  textColor = '',
  fullWidth,
  fullWidthMobile,
  disabled,
  children,
  className,
  tag = 'button',
  isSubmitting,
  ...other
}) {
  const Wrapper = tag === 'a' ? Link : tag;

  return (
    <Wrapper
      className={cn(
        styles.button,
        size && styles[`size${size.toUpperCase()}`],
        theme && styles[`theme${upperFirst(theme)}`],
        textColor && styles[`textColor${upperFirst(textColor)}`],
        fullWidth && styles.fullWidth,
        fullWidthMobile && styles.fullWidthMobile,
        disabled && styles.disabled,
        className,
      )}
      disabled={disabled}
      {...other}
    >
      {children}
      {isSubmitting && <Loader theme="button" duration="2" />}
    </Wrapper>
  );
}

export default Button;
