import React from 'react';
import cn from 'classnames';

import * as styles from './YoutubePlayer.module.scss';

export default function YoutubePlayer({ videoSrc, autoplay = false, theme }) {
  return (
    <div className={cn(styles.mediaPlayer, styles[theme])}>
      <iframe
        className={styles.video}
        src={`https://www.youtube.com/embed/${videoSrc}?rel=0&${autoplay && 'autoplay=1'}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        title="Player"
      ></iframe>
    </div>
  );
}
