const Request = require('./request');
const { getApiUrl } = require('../utils/environment');
const API_LINK = `${getApiUrl()}/api/`;

class Api extends Request {
  constructor() {
    super(API_LINK);
  }

  sendForm(path, data) {
    return this.postFormData(path, data);
  }

  getCaptchaToken() {
    return window.grecaptcha
      .execute('6LfCPMAUAAAAAGPj2JvQRyB45mU8iJabAxT_jLu6', {
        action: 'submit',
      })
      .catch(() => 'no-token');
  }
}

module.exports = new Api();
