import React, { useMemo } from 'react';
import { useLocation } from '@reach/router';
import { getCDNUrl } from 'utils';

const defaultConfig = {
  ogType: 'website',
  twitterType: 'summary_large_image',
  image: 'andersenlab/new-andersensite/logos/andersen-preview-image.png',
  icon: {
    main32: 'andersenlab/new-andersensite/favicon/full-pack/favicon-32x32.png',
    main16: 'andersenlab/new-andersensite/favicon/full-pack/favicon-16x16.png',
  },
};

export default function Head({ url, children, ...props }) {
  const href = useLocation().href;
  const config = useMemo(() => ({ ...defaultConfig, location: url ?? href, ...props }), [href]);

  return (
    <>
      <meta charSet="utf-8" />
      <title>{config.title}</title>
      <meta name="description" content={config.description} />
      <meta property="og:title" content={config.title} />
      <meta property="og:description" content={config.description} />
      <meta property="og:type" content={config.ogType} />
      <meta property="og:url" content={config.location} />
      <meta property="og:image" content={getCDNUrl(config.image)} />
      <meta property="twitter:title" content={config.title} />
      <meta property="twitter:description" content={config.description} />
      <meta property="twitter:card" content={config.twitterType} />
      <meta property="twitter:image" content={getCDNUrl(config.image)} />
      <link rel="icon" type="image/png" sizes="32x32" href={getCDNUrl(config.icon.main32)} />
      <link rel="icon" type="image/png" sizes="16x16" href={getCDNUrl(config.icon.main16)} />
      <link rel="canonical" href={config.location} />
      {children}
    </>
  );
}
