import React, { useState, useLayoutEffect } from 'react';
import Lottie from 'react-lottie-player';

// documentation https://github.com/mifi/react-lottie-player

const Animation = ({ name, path, preserveAspectRatio, className, ...props }) => {
  const [animationData, setAnimationData] = useState();

  useLayoutEffect(() => {
    if (name) {
      const animation = require(`lottieFiles/${name}.json`);
      setAnimationData(animation);
    }
  }, [name]);

  const defaultStyle = {
    height: '100%',
    width: '100%',
  };

  const options = {
    loop: true,
    play: true,
    style: defaultStyle,
    ...props,
  };

  if (preserveAspectRatio) {
    options.rendererSettings = { preserveAspectRatio: 'xMidYMid slice' };
  }

  if (path) {
    return <div className={className}>{<Lottie path={path} {...options} />}</div>;
  }

  return (
    <div className={className}>
      {animationData && <Lottie animationData={animationData} {...options} />}
    </div>
  );
};

export default Animation;
