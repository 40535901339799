import React from 'react';
import { Image, List } from '@landing/ui';

import * as styles from './ProgramItems.module.scss';

export default function ProgramItems({ items }) {
  return (
    <>
      {items.map(({ time, description, list, speaker }) => (
        <div key={time} className={styles.item}>
          <div className={styles.time}>{time}</div>
          <div className={styles.aboutItem}>
            <p>{description}</p>
            {list && <List className={styles.list} items={list} />}
            {speaker && (
              <figure className={styles.speaker}>
                <Image
                  src={speaker.photo}
                  className={styles.speakerPicture}
                  classImage={styles.speakerImg}
                />
                <figcaption className={styles.speakerName}>{speaker.name}</figcaption>
              </figure>
            )}
          </div>
        </div>
      ))}
    </>
  );
}
