import React from 'react';
import cn from 'classnames';
import Markup from '../Markup';
import * as styles from './CaptchaInfo.module.scss';

export default function CaptchaInfo({ captchaBGColor, className, theme, text, additionalText }) {
  const defaultText = `This site is protected by reCAPTCHA and the <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">Google Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer noopener">Terms of Service</a> apply.`;

  return (
    <article className={cn(styles.captcha, styles[captchaBGColor], className, styles[theme])}>
      <Markup className={styles.text}>
        {`${text ?? defaultText}${additionalText ?? ''}`}
      </Markup>
    </article>
  );
}
