import React from 'react';
import cn from 'classnames';

import * as styles from './RadioBtn.module.scss';

function RadioBtn({ name = '', className = '', children, ...rest }) {
  return (
    <label className={cn(styles.radio, className)}>
      <input className={styles.input} type="radio" name={name} {...rest} />
      <span className={styles.checkmark}></span>
      {children}
    </label>
  );
}

export default RadioBtn;
