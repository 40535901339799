import React, { useRef, useState, useEffect } from 'react';
import { Modal, CloseButton, Title, Markup, Button } from '@landing/ui';
import ConsentItem from './ConsentItem';
import { CONSENT_COOKIE_NAME, CONSENT_MODAL_ID, getCookieOptions } from 'define/cookies';
import { setCookie, getCookie } from 'utils/cookie';
import { useClickOutside } from 'utils/hooks';
import { observer, modal as modalStore, cookies as cookiesStore } from 'store';
import { pushCookiePolicyEvent } from 'utils';
import { title, description, blocks, alwaysOn, acceptSelected } from './content.json';

import * as styles from './ConsentModal.module.scss';

function ConsentModal({ animation = 'show', modalID = CONSENT_MODAL_ID }) {
  const modalRef = useRef();
  const isOpen = modalStore.modalID === modalID;
  const [consent, setConsent] = useState({ ad_storage: true, analytics_storage: true });

  useEffect(() => {
    const consentStatus = JSON.parse(getCookie(CONSENT_COOKIE_NAME));
    if (consentStatus) {
      setConsent(prev => ({ ...prev, ...consentStatus }));
    }
  }, []);

  const toggleSwitcher = type => () => {
    setConsent(prev => ({ ...prev, [type]: !prev[type] }));
  };

  const handleAccept = () => {
    setCookie(CONSENT_COOKIE_NAME, JSON.stringify(consent), getCookieOptions());
    cookiesStore.setSeen(true);
    modalStore.close();
    pushCookiePolicyEvent();
  };

  const handleClose = () => {
    if (!getCookie(CONSENT_COOKIE_NAME)) {
      cookiesStore.setSeen(false);
    }
    if (isOpen) {
      modalStore.close();
    }
  };

  useClickOutside([modalRef], handleClose);

  return (
    <Modal
      isOpen={isOpen}
      closeButton={false}
      containerMod="consentModal"
      contentMod="consentModal"
      animation={animation}
    >
      {onClose => (
        <div ref={modalRef}>
          <CloseButton onClose={handleClose} theme="dark" />
          <Title tag="h3">{title}</Title>
          <Markup className={styles.description}>{description}</Markup>
          {blocks.map(({ type, ...block }) => (
            <ConsentItem
              key={type}
              {...block}
              active={consent[type]}
              toggleSwitcher={toggleSwitcher(type)}
              switcherReplaceText={type === 'essential' && alwaysOn}
            />
          ))}
          <Button className={styles.button} onClick={handleAccept} fullWidthMobile>
            {acceptSelected}
          </Button>
        </div>
      )}
    </Modal>
  );
}

export default observer(ConsentModal);
