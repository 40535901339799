import React from 'react';
import cn from 'classnames';
import * as styles from './BurgerBtn.module.scss';

export default function BurgerBtn({ active = false, theme = 'default', onClick, fixed = false }) {
  return (
    <div
      className={cn(styles.wrapper, fixed && styles.fixed)}
      onClick={onClick}
      onKeyPress={() => {}}
      role="button"
      tabIndex={0}
      aria-label="Open the menu"
    >
      <div className={cn(styles.button, styles[theme], active && styles.active)} aria-hidden="true">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
