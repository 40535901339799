export const nonCorporateEmails = [
  '@gmail.',
  '@mail.',
  '@yahoo.',
  '@Outlook.',
  '@aol.',
  '@ya.',
  '@hotmail.',
  '@mailfence.',
  '@protonmail.',
  '@rambler.',
  '@fastmail.',
  '@icloud.',
  '@gmx.',
  '@lavabit.',
  '@meta.',
  '@zoho.',
  '@yandex.',
];
