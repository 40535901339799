import { useEffect, useState, useRef, createContext } from 'react';
import anime from 'animejs';

export const useClickOutside = (refs, handler) => {
  useEffect(() => {
    const targets = refs.map(el => el.current);

    const listener = e => {
      if (
        !targets.some(el => el?.contains && el.contains(e.target)) &&
        typeof handler === 'function'
      ) {
        handler();
      }
    };

    document.addEventListener('mouseup', listener);
    document.addEventListener('touchend', listener);
    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [refs, handler]);
};

export const useDropDown = (headRef, dropDownRef, arrowUpRef, arrowDownRef, onClose) => {
  const animationDuration = 200;
  const animationElasticDuration = animationDuration * 3;
  const animationEasing = 'easeOutCubic';

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const isVisible = useRef(false);

  useEffect(() => {
    const dropdown = dropDownRef.current;

    dropdown.style.opacity = 0;
    dropdown.style.zIndex = -1;
    dropdown.style.transform = 'scale(0.98)';
  }, []);

  useLazyEffect(() => {
    if (isDropDownOpen) {
      open();
    } else {
      close();
    }
  }, [isDropDownOpen]);

  useClickOutside([headRef, dropDownRef], () => setIsDropDownOpen(false));

  const open = () => {
    const dropdown = dropDownRef.current;

    isVisible.current = true;
    anime.remove([arrowUpRef.current, arrowDownRef.current, dropDownRef.current]);

    anime({
      targets: arrowDownRef.current,
      translateY: [0, 5],
      opacity: [1, 0],
      duration: animationDuration,
      easing: animationEasing,
      complete: () => {
        anime({
          targets: arrowUpRef.current,
          translateY: [5, 0],
          rotateZ: {
            value: '180deg',
            duration: 0,
          },
          opacity: [0, 1],
          duration: animationDuration,
          easing: animationEasing,
        });
      },
    });

    anime({
      targets: dropDownRef.current,
      opacity: 1,
      scale: {
        value: 1,
        duration: animationElasticDuration,
        easing: 'easeOutElastic(4, .5)',
      },
      duration: animationDuration,
      easing: animationEasing,
      begin: () => {
        dropdown.style.zIndex = 999;
        dropdown.style.pointerEvents = 'auto';
      },
    });
  };

  const close = () => {
    const dropdown = dropDownRef.current;

    anime.remove([arrowUpRef.current, arrowDownRef.current, dropDownRef.current]);

    anime({
      targets: arrowUpRef.current,
      translateY: [0, -5],
      opacity: [1, 0],
      duration: animationDuration,
      easing: animationEasing,
      complete: () => {
        anime({
          targets: arrowDownRef.current,
          translateY: [-5, 0],
          opacity: [0, 1],
          duration: animationDuration,
          easing: animationEasing,
          complete: () => {
            if (typeof onClose === 'function') {
              onClose();
            }
          },
        });
      },
    });

    anime({
      targets: dropDownRef.current,
      opacity: 0,
      duration: animationDuration,
      easing: animationEasing,
      scale: 0.98,
      complete: () => {
        dropdown.style.zIndex = -1;
        dropdown.style.pointerEvents = 'none';
        isVisible.current = false;
      },
    });
  };

  return {
    open: () => setIsDropDownOpen(true),
    close: () => setIsDropDownOpen(false),
    toggle: () => {
      setIsDropDownOpen(!isDropDownOpen);
      return !isDropDownOpen;
    },
    isOpen: isDropDownOpen,
  };
};

export const useLazyEffect = (fn, deps) => {
  const isRendered = useRef(false);

  useEffect(() => {
    if (isRendered.current) {
      fn();
    } else {
      isRendered.current = true;
    }
  }, deps);
};

export const getIsSsr = () => {
  return process.env.NODE_ENV === 'production';
};

export const ScrollContext = createContext();

export const lockUnlockPage = {
  modals: new Set(),
  lock(modalId) {
    if (!modalId) throw new Error('Specify modal Id');
    this.modals.add(modalId);
    document.documentElement.setAttribute('style', 'overflow: hidden;');
    document.body.setAttribute('style', 'overflow-y: scroll;');
  },
  unlock(modalId) {
    if (!modalId) throw new Error('Specify modal Id');
    this.modals.delete(modalId);
    if (this.modals.size === 0) {
      document.documentElement.setAttribute('style', '');
      document.body.setAttribute('style', '');
    }
  },
};
