import React, { useCallback, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { CloseButton, Loader } from '../../index';
import { ScrollContext, lockUnlockPage } from '../utils';
import * as styles from './Modal.module.scss';

export default function Modal({
  isOpen,
  loader,
  closeModalCallback,
  animation = 'show',
  component,
  children,
  closeButton = true,
  containerMod,
  contentMod,
  theme,
  className,
}) {
  const [state, setState] = useState({ active: false, disable: true });
  const [modalId] = useState(Math.random().toString(16).slice(-8));
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setState(prev => ({ ...prev, active: true, disable: false }));
      isOpen && lockUnlockPage.lock(modalId);
    }
    return () => lockUnlockPage.unlock(modalId);
  }, [isOpen, modalId]);

  const onClose = useCallback(() => {
    setState(prev => ({ ...prev, active: false }));
  }, []);

  const onAnimationEnd = useCallback(() => {
    !state.active && closeModalCallback();
  }, [state.active]);

  if (!isOpen) return null;

  const Children = component
    ? component
    : typeof children === 'function'
    ? children
    : () => children;

  return ReactDOM.createPortal(
    <ScrollContext.Provider value={modalRef.current}>
      <section
        id="modal"
        ref={modalRef}
        className={cn(
          styles.popup,
          state.disable && styles.disable,
          theme && styles[theme],
          className,
        )}
        onClick={event => {
          if (event.target.classList.value !== modalRef.current.className) return;
          onClose();
        }}
      >
        {closeButton && <CloseButton onClose={onClose} />}
        {loader && <Loader />}
        <div
          className={cn(
            styles.container,
            state.active && styles.active,
            loader && styles.loading,
            animation && styles[animation],
            containerMod && styles[containerMod],
          )}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={cn(styles.content, contentMod && styles[contentMod])}>
            {Children(onClose, onAnimationEnd)}
          </div>
        </div>
      </section>
    </ScrollContext.Provider>,
    document.getElementById('___gatsby'),
  );
}
