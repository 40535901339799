import React, { useState } from 'react';
import { CollapseEl, Markup } from '@landing/ui';
import { Switcher } from 'components/UI';
import cn from 'classnames';

import * as styles from './ConsentItem.module.scss';

export default function ConsentItem({
  title,
  description,
  active,
  toggleSwitcher,
  switcherReplaceText,
  className,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleListHandler = () => setIsOpen(!isOpen);

  return (
    <>
      <div className={styles.panelWrapper}>
        <button
          className={cn(styles.panel, className, isOpen && styles.active)}
          onClick={toggleListHandler}
        >
          {title && <span className={styles.title}>{title}</span>}
        </button>
        {!switcherReplaceText ? (
          <Switcher theme="reverseColors" active={active} onChange={toggleSwitcher} />
        ) : (
          <p className={styles.replaceText}>{switcherReplaceText}</p>
        )}
      </div>
      <CollapseEl toggle={isOpen} className={styles.panelContentWrapper}>
        <Markup className={styles.description} tag="p">
          {description}
        </Markup>
      </CollapseEl>
    </>
  );
}
