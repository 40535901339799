import React from 'react';
import cn from 'classnames';
import { Title } from '@landing/ui';
import { Contacts } from 'components/UI';
import Logos from './components/Logos';

import * as styles from './ModalAside.module.scss';

export default function ModalAside({ className, theme }) {
  const customers = (
    <>
      <p className={styles.sectionTitle}>Customers who trust us</p>
      <Logos />
    </>
  );

  return (
    <div className={cn(styles.wrapper, className, theme && styles[theme])}>
      <section className={styles.section}>
        <Title className={styles.title} color="white">
          Have a question? Reach out to Andersen to receive a free consultation and entrust your IT
          initiative to a company of software experts.
        </Title>
      </section>
      <section className={styles.section}>
        <p className={styles.sectionTitle}>Contact us</p>
        <Contacts className={styles.contacts} theme="column" />
      </section>
      <section className={styles.section}>{customers}</section>
    </div>
  );
}
